<template>
  <header id="header-rbk" class="fixed-top">
    <div class="mx-4 d-flex align-items-center justify-content-between">
      <div class="">
        <div class="d-flex align-items-center">
          <img class="logo_entreprise" src="@/assets/images/logo.png" alt="" />
          <h6 class="">K-talent</h6>
        </div>
      </div>
      <a
        class="navbar-toggler d-md-none d-block"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          height="30"
          stroke="#000"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </a>
      <nav class="navbar-rbk d-none d-md-block">
        <ul>
          <li>
            <router-link
              :to="{ name: 'OffreDisponible' }"
              style="color: #1d4851"
              >Offres D'emploi</router-link
            >
          </li>
          <li v-if="user?.role === 'recruteur'">
            <router-link
              :to="{ name: 'dashboard_recruteur' }"
              style="color: #1d4851"
              >Espace Recruteur</router-link
            >
          </li>
          <li v-if="user?.role === 'candidat'">
            <router-link
              :to="{ name: 'dashboard_candidat' }"
              style="color: #1d4851"
              >Espace Candidat</router-link
            >
          </li>

          <router-link :to="{ name: 'login' }" v-if="!$store.getters.getToken"
            ><button class="btn" type="button" id="R_btn_connexion">
              Connexion
            </button></router-link
          >
        </ul>
      </nav>
      <div
        class="offcanvas offcanvas-start bg-secondary shadow"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <a href="/" class="mx-3 pb-3 mb-md-0 logo-part"> </a>

          <a
            type="button"
            class="btn-close text-reset bg-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>

        <div class="offcanvas-body text-white px-4">
          <div class="">
            <div class="pcoded-inner-navbar main-menu">
              <ul
                class="pcoded-item pcoded-left-item"
                style="display: block !important"
              >
                <li class="">
                  <router-link
                    :to="{ name: 'OffreDisponible' }"
                    class="waves-effect waves-dark w-100"
                  >
                    <span class="pcoded-micon"> </span>
                    <i class="fas fa-user-plus text-white"></i
                    ><span class=" text-white">Offres d'emploie</span>
                  </router-link>
                </li>
                <li v-if="user?.role === 'recruteur'">
                  <router-link
                    :to="{ name: 'dashboard_recruteur' }"
                    style="color: #1d4851"
                    class="text-white w-100"
                    ><i class="fas fa-calendar-check text-white"></i>Espace
                    Recruteur</router-link
                  >
                </li>
                <li v-if="user?.role === 'candidat'">
                  <router-link
                    :to="{ name: 'dashboard_candidat' }"
                    style="color: #1d4851"
                    class="text-white"
                    ><i class="fas fa-calendar-check text-white"></i>Espace
                    Candidat</router-link
                  >
                </li>
                <li>  <router-link :to="{ name: 'login' }" v-if="!$store.getters.getToken"
            ><button class="btn mt-3" type="button" id="R_btn_connexion">
              Connexion
            </button></router-link
          ></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style>
.R-logo_entreprise {
  width: 10%;
  height: 20%;
  margin-top: -10%;
  margin-left: 18%;
}

a {
  text-decoration: none;
}

#R_btn_connexion {
  background-color: #1d4851;
  color: white;
}
#R_btn_connexion:hover {
  background-color: #cc5500;
}
.logo_entreprise {
  width: auto;
  height: 55px;
}
.text_recruit {
  margin-right: 1%;
}
</style>
