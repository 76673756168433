<template>
  <nav
    class="navbar header-navbar pcoded-header iscollapsed"
    header-theme="themelight1"
    pcoded-header-position="fixed"
  >
    <div
      class="navbar-wrapper mx-4 d-flex align-items-center justify-content-between"
      :style="hasSideBar ? 'width:100%;' : ''"
    >
      <div class="">
        <div class="d-flex align-items-center">
          <img class="logo_entreprise" src="@/assets/images/logo.png" alt="" />
          <h6 class="">K-talent</h6>
        </div>
      </div>
      <a
        class="navbar-toggler d-md-none d-block"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          height="30"
          stroke="#000"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </a>
      <div class="navbar-container d-none d-md-block" :style="hasSideBar">
        <ul class="nav-right d-block">
          <li @click="goToDashboard()" class="header-notification">
            Tableau de board
          </li>
          <li @click="show_offres()" class="header-notification">
            Offres d'emploi
          </li>

          <li class="user-profile header-notification">
            <div class="dropdown-primary dropdown">
              <div class="dropdown-toggle" data-toggle="dropdown">
                <img
                  :src="profil_photo"
                  class="img-fluid rounded"
                  alt="User-Profile-Image"
                />

                <i class="feather icon-chevron-down"></i>
              </div>
              <ul
                class="show-notification profile-notification dropdown-menu"
                data-dropdown-in="fadeIn"
                data-dropdown-out="fadeOut"
              >
                <!-- <li v-if="this.$store.getters.getRole == 'recruteur'">
                  <router-link :to="{ name: 'Paiement' }">
                    <i class="fas fa-credit-card"></i> Paiement
                  </router-link>
                </li> -->
                <li @click="logout()">
                  <a> <i class="feather icon-log-out"></i> Deconnexion </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div
        class="offcanvas offcanvas-start bg-secondary shadow"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <a href="/" class="mx-3 pb-3 mb-md-0 logo-part"> </a>

          <a
            type="button"
            class="btn-close text-reset bg-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>

        <div class="offcanvas-body text-white px-4">
          <div class="">
            <div class="pcoded-inner-navbar main-menu">
              <ul
                class="pcoded-item pcoded-left-item"
                style="display: block !important"
              >
                <li class=" ">
                  <router-link
                    class="waves-effect waves-dark"
                    :to="{ name: 'dashboard_recruteur' }"
                  >
                    <span class="pcoded-micon"> </span>
                    <i class="fas fa-tachometer-alt text-white"></i
                    ><span class="tout">Tableau de Bord</span>
                  </router-link>
                </li>
                <li class="">
                  <router-link
                    class="waves-effect waves-dark"
                    :to="{ name: 'profil_recruteur' }"
                  >
                    <span class="pcoded-micon"> </span>
                    <i class="fas fa-user text-white"></i
                    ><span class="tout">Mon profil</span>
                  </router-link>
                </li>
                <li class=" " @click="show_offres()">
                  <a class="waves-effect waves-dark">
                    <span class="pcoded-micon"> </span>
                    <i class="fas fa-user-plus text-white"></i
                    ><span class="tout">Offres d'emploie</span>
                  </a>
                </li>
                <!-- <li class=" ">
                  <router-link
                    :to="{ name: 'EvaluationView' }"
                    class="waves-effect waves-dark"
                  >
                    <span class="pcoded-micon">
                    </span>
                    <i class="fas fa-tasks text-white"></i
                    ><span class="tout">Mes évaluations</span>
                  </router-link>
                </li> -->
                <!-- <li class=" ">
                  <router-link
                    :to="{ name: 'EntretienView' }"
                    class="waves-effect waves-dark"
                  >
                    <span class="pcoded-micon">
                    </span>
                    <i class="fas fa-calendar-check text-white"></i
                    ><span class="tout">Mes entretiens</span>
                  </router-link>
                </li> -->
                <li @click="logout()" class="texte-white">
                  <a class="text-white tout"> <i class="feather icon-log-out me-2"></i> Deconnexion </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    Sidebar: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      user: this.$store.getters.getUser,
    };
  },
  computed: {
    hasSideBar() {
      if (!this.Sidebar) {
        return "margin-right: -65%;";
      } else {
        return "";
      }
    },
    profil_photo() {
      if (this.$store.getters.getRole == "candidat") {
        if (this.user.photo != null) {
          return this.user.photo.startsWith(process.env.API_URL)
            ? this.user.photo
            : `${process.env.API_URL}${this.user.photo}`;
        } else {
          return "../../assets/img/default_user.png";
        }
        // return this.user.photo !=null ? `${process.env.API_URL}${this.user.photo}` : '../../assets/img/default_user.png'
      } else {
        if (this.user?.logo != null) {
          return this.user.logo.startsWith(process.env.API_URL)
            ? this.user?.logo
            : `${process.env.API_URL}${this.user?.logo}`;
        } else {
          return "../../assets/img/default_user.png";
        }
        // return this.user.logo !=null ? `${process.env.API_URL}${this.user.logo}` : '../../assets/img/default_user.png'
      }
    },
  },
  methods: {
    goToDashboard() {
      this.$router.push({ name: `dashboard_${this.$store.getters.getRole}` });
    },
    show_offres() {
      this.$router.push({ name: "OffreDisponible" });
    },
    logout() {
      this.$store.commit("LOGOUT");
      this.$router.push({ name: "Home_Page" });
    },
    openPart(data) {
      this.$router.push({ name: data });
      if (this.$router.push({ name: data })) {
        const a = document.querySelector("a[data-bs-dismiss='offcanvas']");
        a.click();
      }
    },
  },
};
</script>

<style scoped>
.nav-left {
  margin-bottom: 0;
}

.evaluation {
  margin-bottom: -90%;
}

li a {
  width: 100% !important;
  color: #fff;
}
li a span {
  color: #fff;
}
.text_recruit {
  margin-right: 1%;
}
.logo_entreprise {
  width: auto;
  height: 55px;
}
</style>
