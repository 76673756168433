<template>
    <DashboardRecruteur>

        <div class="card">
            <div class="card-header">
                <h3>Contestations</h3>
               
            </div>

            <div class="row card-block">
                <div class="col-md-12">
                    <table class="table table-hover">
                            <thead>
                                <tr >
                                    <th>Nom et Prénoms</th>
                                   
                                    <th>contestation</th>
                                    <th>fichier de contestation</th>
                                    <th>Note</th>
                                    <th>Actions</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                    <tr v-for="(item, index) in candidatures" :key="index">

                                    
                                <td>{{ item.candidat.nom }} {{ item.candidat.prenoms }}</td>
                                <td >{{item.contestation}}</td>
                               
                                <td v-if="item.contestation_file!=null">Oui</td>
                                <td v-else>Non</td>
                                <td>
                                    <span>
                                        {{ item.note}}
                                    </span>
                                   
                                </td>
                                <td>
                                    <div class="btn-group">
                            <button class="btn btn-sm dropdown-toggle" style="background-color: #1D4851; color: white;" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Options
                            </button>
                            <ul class="dropdown-menu">
                              <li><a class="dropdown-item" href="#" @click="info_candidat(item.candidat.id)">infos sur le candidat</a></li>
                              <li v-if="item.contestation_file!=null"><a class="dropdown-item" :href="process.env.API_URL+item.contestation_file">voir le fichier</a></li>
                              <li>
                                <hr class="dropdown-divider">
                              </li>
                              <li v-if="item.statut =='Rejeté'"><a class="dropdown-item" href="#" style="color: #0b6b97;" @click="reprendre_candidature(item.id,index)">Reprendre le candidat</a></li>
                            </ul>
                          </div>
                                </td>
                                
                                </tr>
                            </tbody>
                        </table>
                </div>
            </div>
        </div>

    </DashboardRecruteur>
</template>
<script>
import DashboardRecruteur from '@/views/recruteur/DashboardView.vue';
import { OffreEmploi } from '@/services/offre_emploi.service';
import { Candidature } from '@/services/candidature.service';
export default {
    components: { DashboardRecruteur },
    // id represente l'id de l'offre 
    props: ['id'],
    mounted() {
        OffreEmploi.get_offre(this.id)
            .then(res =>{ this.offre_emploi = res.data;console.log(res.data);})
            .catch(err => console.log(err))

            Candidature.candidatures(this.id)
            .then(res=>{
                this.candidatures = res.data.filter(c=>{
                    return c.statut =="Rejeté" && (c.contestation !=null || c.contestation !='')
                })
                
            })
            .catch(err=>console.log(err))
    },
    data() {
        return {
            offre_emploi: {
                id:0,
                poste: {}
            },
            candidatures:[]
        }
    },
    methods: {
        info_candidat(candidat_id){
            this.$router.push({name:'InfoCandidat',params:{id:candidat_id}})
        },
        reprendre_candidature(id,index){
            Candidature.reprendre_candidature(id).then(
                res=>{
                    this.candidatures.splice(index,1)
                    this.$swal.fire({title:'Candidat racheté !',icon:'success'})
                }
            ).catch(err=>{
                console.log(err);
                this.$swal.fire({title:'Opération échouée!',icon:'error'})
            })
        }
    }
}
</script>

<style></style>