<template>
  <DashboardCandidat>
    <div>
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="doc">Mes Cv</h3>
            <div class="card-header-right">
              <button
                class="mybtn btn-sm btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Nouveau cv
              </button>
            </div>
          </div>
          <div class="card-block mx-3">
            <form action="" @submit.prevent="save_doc()">
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Nouveau CV
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <div class="mb-2">
                        <div class="form-outline">
                          <input
                            v-model="new_document.nom"
                            type="text"
                            placeholder="Nom du cv"
                            class="form-control bg-light"
                            required
                          />
                        </div>
                      </div>
                      <div class="mb-2">
                        <div class="form-outline">
                          <input
                            @change="($event) => fileChange($event)"
                            type="file"
                            class="form-control"
                            name="logo"
                            accept="image/jpeg, image/png, image/jpg, application/pdf"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="reset"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Fermer
                      </button>
                      <button type="submit" class="btn btn-primary">
                        <div
                          class="spinner-grow text-light"
                          role="status"
                          v-if="loading"
                        ></div>
                        créer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div class="row mt-3">
              <table
                class="table table-borderless"
                v-if="documents.length !== 0"
              >
                <thead>
                  <tr>
                    <th>Nom du document</th>
                    <th>Date d'enregistrement</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in documents" :key="index">
                    <td>
                      {{ item.nom }}
                    </td>
                    <td>{{ getDate(item.date_enregistrement) }}</td>
                    <td>
                      <div class="btn-group">
                        <button
                          class="btn btn-sm dropdown-toggle btn-primary"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Options
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              :href="item.cv"
                              target="_blank"
                              >Voir</a
                            >
                          </li>

                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              href="#"
                              style="color: #f53838"
                              @click="delete_doc(item.id, index)"
                              >Supprimer</a
                            >
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p v-else class="fs-6 text-center w-100">Aucun cv</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardCandidat>
</template>

<script>
import DashboardCandidat from "./DashboardView.vue";
import { Document } from "@/services/document.service";
import { accountService } from "@/services/account.service";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: { DashboardCandidat },
  mounted() {
    Document.my_documents_cv(this.user?.id)

      .then((res) => {
        this.documents = res.data.results;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      loading: false,
      user: this.$store.getters.getUser,
      documents: [],
      new_document: {
        nom: null,
        document: null,
      },
      showform: false,
    };
  },
  computed: {
    // lien_document() {
    //   return this.documents?.map(
    //     (doc) => `${process.env.API_URL}${doc.document}`
    //   );
    // },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    fileChange(e) {
      const maxSize = 10000000;
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const file = e.target.files[0];
      if (file.size > maxSize) {
        this.$swal.fire({ title: "fichier trop volumineux", icon: "error" });
      } else if (!allowedFileTypes.includes(file.type)) {
        this.$swal.fire({
          title: "format de fichier incorrect",
          icon: "error",
        });
      } else {
        this.new_document.document = e.target.files[0];
      }
    },
    definir_cv(id) {
      Document.update_candidat_doc({ document_id: id, type: "cv" })
        .then((res) => {
          accountService.saveUser(res.data.user);
          this.$swal
            .fire({ title: "Mise a jour Réussi", icon: "success" })
            .then((result) => this.$router.go(0));
        })
        .catch((err) => {
          this.$swal.fire(
            "Operation echouée",
            "Ce document est deja utilisé comme lettre de motivation",
            "error"
          );
        });
    },
    definir_lettre(id) {
      Document.update_candidat_doc({
        document_id: id,
        type: "lettre_motivation",
      })
        .then((res) => {
          accountService.saveUser(res.data.user);
          this.$swal
            .fire({ title: "Mise a jour Réussi", icon: "success" })
            .then((result) => this.$router.go(0));
        })
        .catch((err) => {
          this.$swal.fire(
            "Operation echouée",
            "Ce document est deja utilisé comme CV",
            "error"
          );
        });
    },
    save_doc() {
      this.loading = true;
      if (this.new_document.document == null) {
        this.$swal.fire({ title: "fichier non sélectionné", icon: "error" });
      } else {
        let mydata = new FormData();
        mydata.append("nom", this.new_document.nom);
        mydata.append("cv", this.new_document.document);
        mydata.append("candidat", this.user?.id);

        Document.save_document_cv(mydata)
          .then((res) => {
            this.loading = false;
            this.$swal
              .fire({ title: "Document créé", icon: "success", timer: 3000 })
              .then(this.$router.go(0));
          })
          .catch((err) => {
            this.loading = false;
            this.$swal.fire({ title: "Operation échouée", icon: "error" });
          });
      }
    },
    delete_doc(id, index) {
      Swal.fire({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez plus revenir en arrière",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Quitter",
      }).then((result) => {
        if (result.isConfirmed) {
          Document.delete_document(id)
            .then((res) => {
              this.documents.splice(index, 1);
              Swal.fire(
                "Supprimé!",
                "La suppression a bien été effectuée",
                "success"
              );
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Le document n'a pas pu être supprimé ! Peut-être est-il déjà lié à une candidature.",
              });
            });
        }
      });
    },
    openForm() {
      this.showform = true;
    },
    closeForm() {
      this.showform = false;
    },
  },
};
</script>
<style scoped>
.dropdown-item {
  cursor: pointer;
}

.lescard {
  margin-top: -5%;
}

.doc {
  font-weight: bold;
  color: #1d4851;
}

.card-title {
  margin-left: 30%;
  color: #1d4851;
  font-weight: bold;
}

.card-titles {
  margin-left: 20%;
  color: #1d4851;
  font-weight: bold;
}

.img_cv {
  width: 150px;
  height: 150px;
  margin-left: 20%;
}

.img_doc {
  width: 150px;
  height: 150px;
  margin-left: 20%;
}

.form-label {
  margin-left: 34%;
}

.form-labels {
  margin-left: 20%;
}
</style>
