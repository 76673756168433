<template>
    <DashboardCandidat>
        <div>
            <h3>Details de candidature</h3>
        </div>
        <div class="row">
            <div class="card">
                <div class="card-header">
                    <h4> Poste de {{ candidature.offre_emploi.poste }}</h4>
                    <div class="card-header-right"> <button class="btn mybtn" v-if="candidature.statut == 'Rejeté'" @click="show_form()">
                            contester</button></div>
                </div>
                <div class="card-block ">
                    <div class="h4">Statut de votre candidature : <span class="badge badge-info float-end w-25"
                            v-if="candidature.statut == 'En cours'">{{ candidature.statut }}</span> <span
                            class="badge badge-warning float-end w-25" v-if="candidature.statut == 'Rejeté'">{{
                                candidature.statut }}</span> </div>
                    <div v-if="candidature.statut != 'Rejeté'">
                        <div class="h4 my-3">Evaluation en cours </div>
                        <div class="card comp-card col-6" v-if="evaluations.length != 0"
                            v-for="(item, index) in evaluations" :key="index">
                            <div class="card-header d-flex justify-content-between">
                                <div>
                                    <h3 class="titre">{{ item.nom }}</h3>
                                </div>
                                <div class="dropdown">
                                    <button class="btn btn-sm btn-primary " type="button" @click="passer_eval(item.id)">
                                        effectuer
                                    </button>

                                </div>
                            </div>
                            <div class="card-body">
                                <ul>

                                    <li class="f-w-500 text-c-red ">prend fin le : {{ dates_fin[index] }} </li>

                                </ul>
                            </div>
                        </div>
                        <div v-else>
                            <p class="class f-w-400 text-c-blue">
                                Aucune évaluation disponible pour le moment....
                            </p>
                        </div>



                        <div class="h4 my-3">Entretien en attente </div>
                        <div class="card comp-card col-6">
                            <div class="card-header d-flex justify-content-between">
                                <div>
                                    <h3 class="titre">rrrrrrrrrr</h3>
                                </div>

                            </div>
                            <div class="card-body">
                                <ul>
                                    <li class="f-w-500">https://hkuqskgfyizzizsssssssssssssssssss</li>
                                    <li class="f-w-500 text-c-blue ">commence le : 10/10/2023</li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-7 mt-4" v-show="form_showed">
                        <form @submit.prevent="contester">
                            <div class="form-floating mb-3">
                                <textarea v-model="contestation.texte" class="form-control" id="msgcont" name="" cols="30" rows="10" required></textarea>
                                <label for="msgcont">Message de contestation</label>

                            </div>
                            <div class="form-outline">
                                <label class="form-label" for="form3Example2" style="color: #1D4851;">Document (facultatif)</label>
                                <input @change="$e=>filechanged($e)" type="file" class="form-control" name="fichier">
                            </div>

                            <button class="btn mybtn m-3" type="submit">contester mon rejet</button>
                        </form>
                    </div>

                </div>
            </div>
        </div>


    </DashboardCandidat>
</template>
      
<script >
import DashboardCandidat from './DashboardView.vue'
import { Candidature } from '@/services/candidature.service';
import { Evaluation } from '@/services/evaluation.service';
export default {
    components: { DashboardCandidat },
    props: ['id'],
    mounted() {
        Candidature.candidature(this.$route.params.id, this.$route.query.id).then(res => {
            console.log(res);
            this.candidature = res.data
            console.log(this.candidature)
            // Evaluation.evaluation_offres(this.candidature.offre_emploi.id).then(
            //     res => {
            //         this.dates_fin = res.data.dates_fin
            //         const evals = res.data.evaluations
            //         evals.forEach(el => {
            //             Evaluation.detail_evaluation(el).then(res => this.evaluations.push(res.data)).catch(err => console.log(err))
            //         });
            //         console.log(this.evaluations);
            //     }

            // )
        }).catch(err => console.log(err))


    },
    data() {
        return {
            candidature: {
                offre_emploi: {
                    poste: ''
                }
            },
            evaluations: [],
            dates_fin: [],
            contestation:{
                texte:'',
                file:null
            },
            form_showed:false,
            mydata:new FormData()
        }
    },
    methods: {
        passer_eval(eval_id) {
            this.$router.push({ name: 'pass_evaluation', params: { id: eval_id } })

        },
        filechanged(e){
            this.contestation.file = e.target.files[0]
            this.mydata.append('contestation_file', e.target.files[0])
        },
        show_form(){
            this.form_showed=true
        },
        contester(){
            this.mydata.append('contestation',this.contestation.texte)

            Candidature.contester(this.candidature.id,this.mydata).then(res=>{
                this.$swal.fire({title:'Contestation éffectuée',icon:'success'})
                this.mydata = new FormData()
            }).catch(err=>{
                console.log(err);
                this.$swal.fire({title:'Opération',icon:'error'})
                this.mydata = new FormData()

            })
        }
    },
    computed: {

    }
};
</script>
<style scoped>
.lecard {

    margin-left: 1%;
    /* width: 900px; */
}

.infocandidat {
    color: #1D4851;
    font-weight: bold;
    font-size: 20px;
}
</style>