import axios from 'axios'
import store from '@/store'
const Axios = axios.create({
    baseURL:process.env.API_URL
})

Axios.interceptors.request.use(request=>{
   const token = store.getters.getToken
    // if(token){
    //     request.headers.Authorization= 'Bearer '+token
    // }
    return request

})
export default Axios
