import Axios from "./caller.service";

let get_all_centre_interet = () => {
  return Axios.get("api/centre_interet/");
};

let get_all_competence = () => {
  return Axios.get("api/competence/");
};
let get_all_post = () => {
  return Axios.get("api/poste/");
};

let get_all_niv_etude = () => {
  return [
    "AUCUN",
    "CEP",
    "BEPC",
    "CAP",
    "DTI",
    "BAC",
    "BAC +1",
    "BAC +2",
    "LICENCE",
    "BAC +4",
    "BAC +5",
    "MASTER",
  ];
};

let get_niv_etude = (id) => {
  return Axios.get(`api/niveau_etude/${id}/`);
};

export const infoService = {
  get_all_centre_interet,
  get_all_competence,
  get_all_post,
  get_all_niv_etude,
  get_niv_etude,
};
