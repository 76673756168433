<template>
  <section class="vh-1" style="background-color: white">
      <NavbarGuest></NavbarGuest>
      <div class="mt-5">
            <slot></slot>

      </div>
    <footer id="footer-rbk">
      <div class="container d-md-flex py-4">
        <div class="me-md-auto text-center text-md-start">
          <div class="R-copyright">
            &copy; Copyright
            <strong><span>Kapital Humain et Conseils</span></strong
            >. Tous droits réservés.
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import NavbarGuest from "./NavbarGuest.vue";
import "@/assets/js/main.js";
export default {
  components: { NavbarGuest },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style>
/* .icon-box:before {
    content: "";
} */
.R-logo_entreprise {
  width: 10%;
  height: 20%;
  margin-top: -10%;
  margin-left: 18%;
}

/* .text_recruit{
    color: #1D4851;
    font-size: 185%;
} */
a {
  text-decoration: none;
}

/* .R-img_iconeReccherche{
  width: 5%;
  height: 20%;
}
#R_btn_rechercher{
 background-color: #cc5500; 
  color: white; 
  width: 150px; 
  height: 50px;
} */
/* #R_btn_rechercher:hover{
  background-color: #1D4851;
} */
#R_btn_connexion {
  background-color: #1d4851;
  color: white;
}

#R_btn_connexion:hover {
  background-color: #cc5500;
}

#Footer1 {
  margin-left: 80%;
  margin-top: -30%;
}

#R-footer {
  margin-left: 20%;
}
</style>
