<template>
  <div class="nav-list">
    <div class="pcoded-inner-navbar main-menu">
      <ul class="pcoded-item pcoded-left-item sidebar" style="margin-top: 20%">
        <li class=" ">
          <router-link
            class="waves-effect waves-dark"
            :to="{ name: 'dashboard_candidat' }"
          >
            <span class="pcoded-micon"> </span>
            <i class="fas fa-tachometer-alt"></i
            ><span class="tout">Tableau de Bord</span>
          </router-link>
        </li>
        <li class=" ">
          <router-link
            class="waves-effect waves-dark"
            :to="{ name: 'profil_candidat' }"
          >
            <span class="pcoded-micon"> </span>
            <i class="fas fa-user"></i><span class="tout">Mon Profil</span>
          </router-link>
        </li>
        <!-- <li class="">
          <router-link
            class="waves-effect waves-dark"
            :to="{ name: 'experiences' }"
          >
            <span class="pcoded-micon"> </span>
            <i class="fas fa-briefcase experience-icon"></i
            ><span class="tout">Mes Expériences</span>
          </router-link>
        </li> -->
        <li class="">
          <router-link
            class="waves-effect waves-dark"
            :to="{ name: 'Candidature' }"
          >
            <span class="pcoded-micon"> </span>
            <i class="fas fa-file application-icon"></i
            ><span class="tout">Mes candidatures</span>
          </router-link>
        </li>
        <!-- <li class="">
                         <router-link class="waves-effect waves-dark "  :to="{name:'experiences'}">
                           <span class="pcoded-micon">
                         </span>
                         <i class="fas fa-star"></i> <span class="tout">Mes favoris</span>
                         </router-link>
                     </li> -->
        <li class="">
          <router-link
            class="waves-effect waves-dark"
            :to="{ name: 'Document' }"
          >
            <span class="pcoded-micon"> </span>
            <i class="fas fa-folder-open"></i><span class="tout">Mes Cv</span>
          </router-link>
        </li>
        <li class="">
          <router-link
            class="waves-effect waves-dark"
            :to="{ name: 'MotivationCandidat' }"
          >
            <span class="pcoded-micon"> </span>
            <i class="fas fa-folder-open"></i
            ><span class="tout">Mes Motivations</span>
          </router-link>
        </li>
        <!-- <li class="">
                         <router-link class="waves-effect waves-dark "  :to="{name:'experiences'}">
                           <span class="pcoded-micon">
                         </span>
                         <i class="fas fa-user-circle"></i><span class="tout">Mon compte</span>
                         </router-link>
                     </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.fas {
  margin-right: 6%;
}
.tout {
  font-size: 17px;
  font-weight: bold;
}
</style>
