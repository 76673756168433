<template>
    <DashboardCandidat>
<section>

    <div class="card mx-4 mx-md-5 shadow-5-strong" style="
background: hsla(0, 0%, 100%, 0.8);
backdrop-filter: blur(30px);
">
        <div class="card-body py-5 px-md-5">
            <div class="row d-flex justify-content-center">
                <div class="row mb-4 align-items-end">
                    <h3 class="fw-bold  col-md-9" style="color: #1D4851;">Experiences professionnelles</h3>
                    <button class="btn mybtn  col-md-3" @click="goToAddExperience()"> <i class="fas fa-plus"></i>
                        nouvelle experience</button>
                </div>
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-hover m-b-0">
                            <thead>
                                <tr>
                                    <th>Poste/Entreprise</th>
                                    <th>Durée</th>

                                    <th>Description</th>

                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in experiences" :key="index">
                                    <td>
                                        <div class="d-inline-block align-middle">

                                            <div class="d-inline-block">
                                                <h6> <a href="" class="table-link">{{ item.poste.nom }}</a></h6>
                                                <p class="text-muted m-b-0">{{ item.nom_entreprise }}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ item.date_debut }} a {{ item.date_fin }}</td>

                                    <td>{{ shortdescription[index] }}</td>

                                    <td><span class="icoo" @click="editExperience(item.id)"><i
                                                class="icon feather icon-edit f-w-600 f-20 m-r-15 text-c-green"></i></span>
                                        <span class="icoo" @click="deleteExperience(item.id, index)"><i
                                                class="feather icon-trash-2 f-w-600 f-20 text-c-red"></i></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>



            </div>
        </div>
    </div>
</section>
</DashboardCandidat>
</template>

<script>
import { UserExperience } from '@/services/user_experience.service';
import Swal from 'sweetalert2'
import DashboardCandidat from './DashboardView.vue'

export default {
components:{DashboardCandidat},
data() {
    return {
        experiences: [],
        user : this.$store.getters.getUser
    }
},
methods: {
    deleteExperience(id, index) {
        Swal.fire({
            title: 'Êtes-vous sûr(e) ?',
            text: "Vous ne pourrez plus revenir en arrière",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText:'Quitter'
        }).then((result) => {
            if (result.isConfirmed) {
                UserExperience.deleteExperience(id)
                    .then((res) => {
                        this.experiences.splice(index, 1)
                        Swal.fire(
                            'Supprimé!',
                            'La suppression a bien été effectué',
                            'success'
                        )
                    })
                    .catch(err => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'l\'experience n\'a pas pu etre supprimé !',
                                })
                            })

            }
        })
    },
    goToAddExperience() {
        this.$router.push({ name: 'add_experience' })
    },
    editExperience(id) {
        this.$router.push({ name: 'edit_experience', params: { 'exp_id': id } })
    },
},
computed: {
    shortdescription() {
        return this.experiences.map(exp => exp.description.substring(0, 15) + '....')
    }
},
mounted() {
    UserExperience.getExperiences(this.user.id)
        .then(res => {
            this.experiences = res.data
        })
        .catch(err => console.log(err))
}
}
</script>

<style>
.mybtn {
background-color: #cc5500;
color: white;

}

.mybtn:hover {
background-color: #1D4851;
color: white;
}

.table-link {
text-decoration: none;
color: #212529;
font-size: medium;
}

.icoo {
cursor: pointer
}
</style>