<template>
    <DashboardCandidat>
        <div class="card">
            <div class="card-body py-5 px-md-5">
                <div class="row d-flex justify-content-center">
                    <div class="row mb-4">
                        <h3 class=" col-md-9 fw-bold evalu">{{evaluation.nom}}</h3>
                      <div v-for="(quest, index) in evaluation.questions" :key="index">
                       <h4>Question {{ index+1 }} : {{quest.question}} <span class="small text-c-red">  {{ quest.points }} points</span></h4>
                       <ul>
                        <li class="m-2" v-for="(rep, indice) in quest.reponses" :key="indice">Reponse {{ indice+1 }} : {{rep.reponse}} <span class="badge badge-info" v-if="rep.valid"> bonne reponse</span> </li>
                        
                        
                       </ul>
                    </div>
 
                    </div>
                    <div class="row">
                        
                    </div>
                </div>
            </div>
        </div>
    </DashboardCandidat>
</template>

<script>
import DashboardCandidat from '@/views/recruteur/DashboardView.vue'
import { Evaluation } from '@/services/evaluation.service';
export default{
    components:{
        DashboardCandidat
    },
    props:['id'],
    data() {
        return {
            evaluation:{}
        }
    },
    mounted(){
        Evaluation.detail_evaluation(this.id)
        .then(res=>this.evaluation = res.data)
        .catch(err=>console.log(err))
    }
}
</script>