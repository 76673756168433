<template>
  <DashboardRecruteur>
    <div class="card">
      <div class="card-body py-5 px-md-5">
        <div class="row d-flex justify-content-center">
          <div class="row mb-4 align-items-end">
            <h3 class="fw-bold evalu col-md-9">Mes entretiens</h3>

            <button type="button" class="btn monbouton col-md-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Nouveau entretien
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Nouveau entretien</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form action="">
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput"
                          placeholder="Titre de l'offre" required>
                        <label for="floatingInput">Titre de l'offre</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput"
                          placeholder="Lien de l'offre" required>
                        <label for="floatingInput">Lien de l'offre</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input type="datetime-local" class="form-control" id="floatingInput"
                          placeholder="Lien de l'offre" required>
                        <label for="floatingInput">Date et Heure</label>
                      </div>
                      <textarea name="" id="" cols="60" rows="3" placeholder="Description"></textarea>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn bouton" data-bs-dismiss="modal">Fermer</button>
                    <button type="button" class="btn boutonnn">Créer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card">
            <table class="table table-triped">
              <thead>
                <th class="titre">Titre de l'offre</th>
                <th class="titre">Lien de l'offre</th>
                <th class="titre">Statut</th>
                <th class="titre">Date</th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td>Comptabilité</td>
                  <td>https://lorem ipsum dolor</td>
                  <td><label class="badge badge-info">Terminé</label></td>
                  <td>12/O3/2023</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn mb-2 btnn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span class="opt">Option</span>
                      </button>
                      <ul class="dropdown-menu">
                        <li><button class="dropdown-item" type="button">Assigner à un candidat</button></li>
                        <li><button class="dropdown-item" type="button">Modifier</button></li>
                        <li><button class="dropdown-item" type="button">Supprimer</button></li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Informatique</td>
                  <td>https://lorem ipsum dolor</td>
                  <td><label class="badge badge-warning">En attente</label></td>
                  <td>12/O8/2023</td>
                  <td>
                    <div class="dropdown">
                      <button class="btn mb-2 btnn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span class="opt">Option</span>
                      </button>
                      <ul class="dropdown-menu">
                        <li><button class="dropdown-item" type="button">Assigner à un candidat</button></li>
                        <li><button class="dropdown-item" type="button">Modifier</button></li>
                        <li><button class="dropdown-item" type="button">Supprimer</button></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </div>
    </div>
  </DashboardRecruteur>
</template>
    
<script >
import DashboardRecruteur from '@/views/recruteur/DashboardView.vue'
export default {
  components: { DashboardRecruteur },
};
</script> 
<style scoped>
.btn {
  background-color: #cc5500;
  color: white;
  font-weight: bold;
}

.btn:hover {
  background-color: #1D4851;
  color: white;
  font-weight: bold;
}

.titre {
  font-weight: bold;
  font-size: 20px;
}

.evalu {
  color: #1D4851;
  font-weight: bold;
}

.btnn {
  background-color: #1D4851;
  color: white;
  font-weight: bold;
}

.btnn:hover {
  background-color: #cc5500;
  color: white;
  font-weight: bold;
}

.modal-title {
  color: #1D4851;
  font-weight: bold;
}
.bouton{
  background-color: #1D4851;
  color: white;
  font-weight: bold;
}
.boutonnn{
  background-color: #cc5500;
  color: white;
  font-weight: bold;
}
.boutonnn:hover{
  background-color: #1D4851;
  color: white;
  font-weight: bold;
}
</style>