<template>
    <GuestLayout>
        <section class="container" style="padding-top: 100px;">
            <div class="card" style="border-radius: 1rem;">
                <div class="row g-0">
                    <div class="col-md-6 col-lg-7 d-none d-md-block img_illustration" style="border-radius: 1rem 0 0 1rem;">
                        <img src="@/assets/images/illustration_pg_connexion.png" alt="login form" class="illustration"
                            style="border-radius: 1rem 0 0 1rem;" />
                    </div>
                    <div class="col-md-6 col-lg-5 d-flex align-items-center">
                        <div class="card-body p-4 p-lg-5 text-black">

                            <form @submit.prevent="confirmForm()">

                                <div class="d-flex align-items-center mb-3 pb-1">
                                    <img class="logo" src="@/assets/images/logo.png" alt="image du logo de l'entreprise">
                                </div>

                                <h5 class="fw-normal mb-3 pb-3 text-center" style="letter-spacing: 1px;"><span
                                        class="phrase_connexion">Reinitialisation de mot de passe</span></h5>
                                <div v-if="have_errors">
                                    <div v-for="error in errors" class="alert alert-danger small"> {{ error }}</div>
                                </div>
                                
                                <div v-show="showForm">
                                    <div class="alert alert-info small"> entrez votre adresse mail pour commencer la
                                        procedure</div>
                                    <div class="form-floating mb-3">
                                        <input v-model="user.email" type="email" class="form-control" id="floatingInput"
                                            placeholder="name@example.com" required>
                                        <label for="floatingInput">Adresse e-mail</label>
                                    </div>
                                    <div class="d-grid gap-2 col-12 mx-auto mb-3">
                                        <button class="btn btn-lg mb-3" type="submit"><span
                                                style="color: white;">verifier</span></button>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </GuestLayout>
</template>
  
<script>

import GuestLayout from '@/components/GuestLayout.vue'
import { accountService } from '@/services'
export default {
    components: { GuestLayout },
    data() {
        return {
            user: {
                email: '',
            },
            showForm: true,
            errors: []
        }
    },
    computed: {
        have_errors() {
            return this.errors.length != 0 ? true : false
        }
    },
    methods: {
        confirmForm() {
            this.errors = []
            if (this.user.email == '') {
                this.errors.push('l\'adresse email est obligatoire')
            }
            if (this.errors.length == 0) {
                this.sendMail()
            }
        },
        sendMail() {
            accountService.sendForgotPasswordMail(this.user)
                .then(res => {
                    this.$swal.fire(
                        'Email envoyé',
                        'Un lien a été envoyé a l\'email renseigné',
                        'success'
                    )
                        this.showForm = false
                    this.errors = []
                })
                .catch(err =>{
                    this.$swal.fire(
                        'Email envoyé',
                        'Un lien a été envoyé a l\'email renseigné',
                        'success'
                    )
                })
        }

    }
}
</script>
  
<style scoped>
.logo {
    width: 30%;
    height: 30%;
    margin-top: -5%;
    margin-left: 25%;
    border-radius: 100%;
    border: 1px;
    box-shadow: 3px 3px 3px 3px rgb(159, 155, 155);
    ;
}

.illustration {
    width: 105%;
    height: auto;
}

.img_illustration {
    background-color: #bfe5ed;

    height: 10%;
}

.phrase_connexion {
    font-weight: bold;
    margin-left: 15%;
    font-size: 125%;
    color: #1D4851;
}

.btn:hover {
    background-color: #cc5500;
}

.btn {
    background-color: #1D4851;
}

.logo_google {
    margin-right: 10%;
}

#btn_google {
    background-color: #FF5733;
    color: white;
}

#btn_google:hover {
    background-color: #fefdfd;
    border: solid 2px #FF5733;
    color: black;
}
</style>
  

