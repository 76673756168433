import Axios from "./caller.service";

let my_evaluations = ()=>{
    return Axios.get('api/evaluation/')
}

let detail_evaluation = (id)=>{
    return Axios.get(`api/evaluation/${id}/`)
}
let create_evaluation =(data)=>{
    return Axios.post('api/evaluation/',data)
}
let delete_evaluation = (id)=>{
    return Axios.delete(`api/evaluation/${id}/`)
}

let assign_evaluation = (data)=>{
    return Axios.post('api/evaluation/assign_to_offre/',data)
}

let evaluation_offres = (offre_id)=>{
    return Axios.get(`api/evaluation/${offre_id}/evaluations_associes`)
}
export const Evaluation ={my_evaluations,detail_evaluation,create_evaluation,delete_evaluation,assign_evaluation,evaluation_offres}