import { createApp } from "vue";
import App from "./App.vue";
import VueSweetalert2 from "vue-sweetalert2";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "vue-advanced-search/dist/AdvancedSearch.css";
import store from "@/store/index";
import router from "@/router/index";
import vSelect from "vue-select";
import CKEditor from "@ckeditor/ckeditor5-vue";

const app = createApp(App);
app.component("v-select", vSelect);
app.use(Toast);
app.use(CKEditor);
app.use(store);
app.use(VueSweetalert2);
app.use(router);

app.mount("#app");
