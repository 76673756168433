<template>
    <DashboardCandidat>
     
        <div class="row">
            <div class="card">
                <div class="card-header">
                    <h3 class=" col-md-9 fw-bold evalu">{{evaluation.nom}}</h3>
                </div>
            <div class="card-body py-5 px-md-5">
                <div class="row d-flex justify-content-center">
                    <div class="row mb-4">
                      <div v-for="(quest, index) in evaluation.questions" :key="index">
                       <h4>Question {{ index+1 }} : {{quest.question}} </h4>
                       <ul>
                        <li class="m-2"  v-for="(rep, indice) in quest.reponses" :key="indice">    <input type="checkbox" v-model="user_reponses[indice].valid" name="" id=""> Reponse {{ indice+1 }} : {{rep.reponse}} </li> 
                       </ul>
                    </div>
 
                    </div>
                   
                </div>
            </div>
            <div class="card-footer">
                    <button class="btn mybtn float-end" @click="soumettre">Soumettre</button>
                   </div>
        </div>

        </div>


    </DashboardCandidat>
</template>
      
<script >
import DashboardCandidat from './DashboardView.vue'
import { Candidature } from '@/services/candidature.service';
import { Evaluation } from '@/services/evaluation.service';
export default {
    components: { DashboardCandidat },
    props:['id'],
    data() {
        return {
            evaluation:{},
            user_reponses:[]
        }
    },
    mounted(){
        Evaluation.detail_evaluation(this.id)
        .then(res=>{
            this.evaluation = res.data
            let reponses = []
            this.evaluation.questions.forEach(element => {
                element.reponses.forEach(el=>{
                    reponses.push(el)
                })
            });
            this.user_reponses = reponses

            console.log(reponses);
        })
        .catch(err=>console.log(err))
    },
    methods: {
        soumettre(){
            console.log(this.user_reponses);
            // this.$swal.fire({
            //     title: 'Êtes-vous sûr(e) ?',
            //     text: "Confirmer la soumission de l'évaluation",
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#3085d6',
            //     cancelButtonColor: '#d33',
            //     confirmButtonText: 'Confirmer',
            //     cancelButtonText: 'Annuler'
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         Evaluation.delete_evaluation(this.id)
            //             .then((res) => {
            //                 this.$swal.fire({
            //                     title:'Evaluation soumise!',
            //                     icon:'success'}
            //                 )
            //             })
            //             .catch(err => {
            //                 this.$swal.fire({
            //                     icon: 'error',
            //                     title: 'Opération échouée',
            //                     text: 'Une erreur s\'est produite !',
            //                 })
            //             })

            //     }
            // })
        }
    },
    computed:{
       
    }
};
</script>
<style scoped>
.lecard {
    
    margin-left: 1%;
    /* width: 900px; */
}

.infocandidat {
    color: #1D4851;
    font-weight: bold;
    font-size: 20px;
}
</style>