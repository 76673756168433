<template>
  <DashboardCandidat>
    <div>
      <div class="row">
        <div class="col-6">
          <div class="card comp-card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-b-25 perso-text">Candidatures en cours</h6>
                  <h3 class="f-w-700 text-c-green perso-text">
                    {{ candidatures_encours }}
                  </h3>
                </div>
                <div class="col-auto">
                  <i class="fas fa-address-card bg-c-yellow"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card comp-card">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-b-25 perso-text">Candidatures archivées</h6>
                  <h3 class="f-w-700 text-c-yellow perso-text">
                    {{ candidatures_archive }}
                  </h3>
                </div>
                <div class="col-auto">
                  <i class="fas fa-check-circle bg-c-green"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardCandidat>
</template>

<script>
import DashboardCandidat from "./DashboardView.vue";
import { Candidature } from "@/services/candidature.service";
export default {
  components: { DashboardCandidat },
  data() {
    return {
      candidatures_encours: 0,
      candidatures_archive: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    Candidature.my_candidatures(this.user.id, false)
      .then((res) => {
        console.log(res);
        this.candidatures_encours = res.data?.count;
      })
      .catch((err) => console.log(err));

    Candidature.my_candidatures(this.user.id, true)
      .then((res) => {
        this.candidatures_archive = res.data?.count;
      })
      .catch((err) => console.log(err));
  },
};
</script>
<style scoped>
section {
  padding: 0px;
}
</style>
