import Axios from "./caller.service";

let create_offre = (data) => {
  return Axios.post("api/offre_emploi/", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

let update_offre = (data, offre_id) => {
  return Axios.put(`api/offre_emploi/${offre_id}/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

let get_offre = (offre_id) => {
  return Axios.get(`api/offre_emploi/${offre_id}/`);
};

let delete_offre = (offre_id) => {
  return Axios.delete(`api/offre_emploi/${offre_id}/`);
};

let get_recruteur_offres = (recruteur_id, is_archive) => {
 return Axios.get(`api/offre_emploi/`, {
    params: { recruteur: recruteur_id ,is_archive:is_archive},
  })
    
};

let get_all_offres = (is_published, is_archive) => {
  return Axios.get("api/offre_emploi/", {
    params: { is_published: is_published, is_archive: is_archive },
  });
};
let get_recruteur_offres_few_detail = () => {
  return Axios.get("api/offre_emploi/few_details/");
};

let postuler = (data) => {
  return Axios.post("api/candidature/", data, {
    headers: { "Content-Type": "multipart/form-data" }
  })
};
let recruteur_statistique = (id) => {
  return Axios.get(`api/recuteur/${id}/get_count_of_candidatures/`);
};

let rouvrir_offre = (id, data) => {
  return Axios.put(`api/offre_emploi/${id}/rouvrir/`, data);
};

let definir_critere = (data) => {
  return Axios.post("api/critere/", data);
};

export const OffreEmploi = {
  create_offre,
  update_offre,
  get_offre,
  get_recruteur_offres,
  delete_offre,
  get_all_offres,
  postuler,
  get_recruteur_offres_few_detail,
  recruteur_statistique,
  rouvrir_offre,
  definir_critere,
};
