import Axios from "./caller.service";

let my_candidatures = (id, isArchive) => {
  return Axios.get("api/get_candidatures_for_a_user/", {
    params: { candidat: id, is_archive: isArchive },
  });
};
let closeOffre = (id, data) => {
  return Axios.patch(`api/offre_emploi/${id}/published_status_update/`, data
  );
};
let candidatures = (offre_id,id) => {
  return Axios.get(`api/get_candidatures`, {
    params: {
      offre_emploi: offre_id,
      id:id
    },
  });
};

let candidatures_statistiques = () => {
  return Axios.get("api/candidature/candidat_statistique/");
};

let candidature = (id) => {
  return Axios.get(`api/candidature/${id}/`);
};

let annule_candidature = (id) => {
  return Axios.delete(`api/candidature/${id}/`);
};

let status_candidature = (id, data) => {
  return Axios.patch(`api/candidature/${id}/update/`, data);
};
let contester = (id, data) => {
  return Axios.put(`api/candidature/${id}/contester/`, data);
};

let reprendre_candidature = (id) => {
  return Axios.put(`api/candidature/${id}/reprendre/`);
};
export const Candidature = {
  my_candidatures,
  candidatures,
  candidatures_statistiques,
  candidature,
  closeOffre,
  annule_candidature,
  // reject_candidature,
  contester,
  status_candidature,
  reprendre_candidature,
};
