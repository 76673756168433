<template>
  <GuestLayout>
    <section
      class="le_background choice"
      style="padding-top: 100px; overflow-y: scroll"
    >
      <div class="row mb-4">
        <div class="col-sm-6 text-center">
          <img
            class="mb-3"
            id="recruter"
            src="@/assets/images/espace_recruteur.jpg"
            alt=""
            style="height: 195px; width: 300px"
          /><br />
          <router-link
            :to="{ name: 'register_recruiteur' }"
            id="btn_recruiter"
            class="btn"
          >
            recruteur
          </router-link>
        </div>
        <div class="col-sm-6 text-center">
          <img
            class="mb-3"
            id="candidat"
            src="@/assets/images/espace_candidat.jpg"
            alt=""
            style="height: 195px; width: 300px"
          />
          <br />
          <router-link
            :to="{ name: 'Signup_Page' }"
            id="btn_recruiter"
            class="btn"
          >
            candidat
          </router-link>
        </div>
      </div>
    </section>
  </GuestLayout>
</template>

<script>
import GuestLayout from "@/components/GuestLayout.vue";
export default {
  components: { GuestLayout },
  name: "choice_role",
  props: {},
};
</script>
<style>
#recruter {
  margin-bottom: 80%;
  transition: 1s;
  width: 45%;
  margin-left: 5%;
}
#recruter:hover {
  margin-bottom: 80%;
  transition: 1s;
  transform: scale(1.2);
}
#candidat {
  margin-bottom: 80%;
  transition: 1s;
  width: 40%;
  margin-left: 5%;
}
#candidat:hover {
  margin-bottom: 80%;
  transition: 1s;
  transform: scale(1.2);
}
#btn_recruiter {
  background-color: #cc5500;
  color: white;
  margin-top: 5%;
  margin-bottom: 10%;
  width: 200px;
  height: 50px;
}
#btn_recruiter:hover {
  background-color: #1d4851;
  color: white;
  transition: 1s;
  transform: scale(1.2);
}
#btn_candidat {
  background-color: #1d4851;
  color: white;
  margin-left: 65%;
  margin-top: -7%;
  width: 200px;
  height: 50px;
}
#btn_candidat:hover {
  background-color: #cc5500;
  color: white;
  transition: 1s;
  transform: scale(1.2);
}
.le_background {
  background-color: rgb(231, 247, 247);
}
</style>
