<template>
    <DashboardRecruteur>

        <div class="row">
            <div class="card">
                <div class="card-header">
                    <h3>Page d'Abonnement</h3>
                </div>
                <div class="card-body row">
                    <div class="col-md-4">
                        <img src="@/assets/images/paiement.jpg" class="img-fluid rounded-start h-100" alt="Shoes" />
                    </div>
                    <div class="col-md-8 text-center " v-if="!this.$store.getters.getAbonne">
                        <p class="paragraph">
                        <h3>Bienvenue sur notre page d'abonnement</h3>
                        En vous abonnant, vous ouvrez les portes à un monde d'opportunités comme créer de nouvelles offres
                        d'emploi et gérer efficacement vos recrutements.
                        Notre plateforme conviviale et intuitive vous permettra de publier rapidement des offres
                        attractives, de trier les candidatures, d'organiser des entretiens et de prendre des décisions
                        éclairées. <br>
                        Avec un simple clic sur le bouton "Payer" ci-dessous, vous débloquez tout le potentiel de votre
                        processus de recrutement. Rejoignez-nous dès maintenant et simplifiez votre recherche de talents
                        exceptionnels.
                        </p>

                        <button class="payer w-50" @click="essai()">M'abonner</button>
                    </div>
                    <div v-else>
                        <p class="paragraph">
                        <h3>Bienvenue sur notre page d'abonnement</h3>
                        Merci pour votre abonnement
                        </p>
                    </div>
                </div>
            </div>
        </div>


    </DashboardRecruteur>
</template>
<script>
import DashboardRecruteur from "./recruteur/DashboardView.vue";
import { accountService } from '@/services/account.service'
import {
    openKkiapayWidget,
    addKkiapayListener,
    removeKkiapayListener,
} from "kkiapay";

export default {
    components: { DashboardRecruteur },
    methods: {
        open() {
            openKkiapayWidget({
                amount: 10000,
                api_key: "9612818020c711ee865bc58489fc3fbb",
                sandbox: true,
                phone: "97000000",
            })
        },

        successHandler(response) {
            accountService.suscribe().then(res => {

                this.$swal.fire({ title: 'Abonnement éffectué', icon: 'success' }).then(isConfirmed => {
                    this.$router.push({ name: 'dashboard_recruteur' })
                })
            }).catch(err => {
                console.log(err)
                this.$swal.fire({ title: 'Abonnement échoué', icon: 'error' })
            })
        },

        mounted() {
            addKkiapayListener('success', this.successHandler)
        },
        beforeDestroy() {
            removeKkiapayListener('success', this.successHandler)
        },
        essai() {
            accountService.suscribe().then(res => {
                this.$store.commit('SET_ABONNE', true)
                this.$swal.fire({ title: 'Abonnement éffectué', icon: 'success' }).then(isConfirmed => {
                    this.$router.push({ name: 'dashboard_recruteur' })
                })
            }).catch(err => {
                console.log(err)
                this.$swal.fire({ title: 'Abonnement échoué', icon: 'error' })
            })
        }
    },
}

</script>

<style>
.card-text {
    font-size: 1rem;
}

.paragraph {
    text-align: justify;
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
}

h3 {
    font-size: 28px;
    color: #333;
}

.payer {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #cc5500;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payer:hover {
    background-color: #eb7e31;
}</style>