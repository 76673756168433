import Axios from './caller.service'

let getExperiences=(candidat_id)=>{
    return Axios.get(`api/experience/${candidat_id}/user_experiences/`)
}
let createExperience=(data)=>{
    return Axios.post('api/experience/',data)
}

let getExperience=(experience_id)=>{
    return Axios.get(`api/experience/${experience_id}/`)
}
let updateExperience=(experience_id,data)=>{
    return Axios.put(`api/experience/${experience_id}/`,data)
}
let deleteExperience = (experience_id)=>{
    return Axios.delete(`api/experience/${experience_id}/`)
}

export const UserExperience={getExperiences,createExperience,updateExperience,getExperience,deleteExperience}