<template>
    <DashboardCandidat>
        <div class="card">
            <div class="card-body py-5 px-md-5">
                <div class="row d-flex justify-content-center">
                    <div class="row mb-4 align-items-end">
                        <h3 class="fw-bold evalu col-md-9">Créer une évaluation</h3>

                    </div>
                    <div class="row mb-3">
                        <div class="col-5"> <input type="text" v-model="titre" class="form-control" placeholder="titre de l'évaluation"></div>
                       
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div class="form-floating">
                                <input v-model="question_actu" type="text" id="question" class="form-control">
                                <label for="question">Question</label>
                                <div class="small text-danger f-w-500 " v-show="cannot_finish">Ce champ est vide</div>
                            </div>
                            <div class="mt-2">
                
                                <label>points accordés:</label> <input v-model="point" type="number" min="1" max="50" class="">
                                <div class="small text-danger f-w-500 " v-show="point_invalid">veuillez renseigner un nombre entre 1 et 50 </div>
                                <div class="small text-danger f-w-500 " v-show="point_vide">Ce champ est vide</div>
                            </div>

                            <div class=" mt-2" v-for="(item, index) in reponses " :key="index">
                                <label>Réponse :</label> <input v-model="item.reponse" type="text" class="">
                                <input type="checkbox" v-model="item.valid" class="mx-2">correcte
                            </div>

                            <div class=" mt-2">
                                <label>Réponse :</label> <input v-model="reponse" type="text" class="">
                                <input type="checkbox" v-model="valid" class="mx-2">correcte
                                <div class="small text-danger f-w-500 " v-show="cannot_add">la derniere réponse est vide
                                </div>
                            </div>
                            <div class="d-flex justify-content-around">
                                <button class="btn btn-sm btn-primary mt-2 " @click="add_reponse">ajouter une
                                    reponse</button>
                                <button class="btn btn-sm btn-success mt-2" @click="add_question">Terminer</button>
                            </div>

                        </div>
                        <div class="col-1"></div>
                        <div class="col-6">
                            <div class="card">
                                <div class="card-header">nombre de question : {{questionnaire.length}}</div>
                                <div class="card-body">
                                    <ul>                                        
                                        <li class="ques m-2 p-2 d-flex justify-content-between rounded" v-for="(item, index) in questionnaire" :key="index">
                                            <div>{{item.question}}</div>
                                            <div>
                                                <span class="icoo" @click=" edit_question(index)"><i
                                                        class="icon feather icon-edit f-w-600 f-20 m-r-15 text-c-green"></i></span>
                                                <span class="icoo" @click="delete_question(index)"><i
                                                        class="feather icon-trash-2 f-w-600 f-20 text-c-red"></i></span>
                                            </div> 
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                                    <button class="btn mybtn float-end" @click="add_evaluation">Créer l'évaluation</button>
                                </div>
        </div>
    </DashboardCandidat>
</template>

<script>
import DashboardCandidat from '@/views/recruteur/DashboardView.vue'
import { Evaluation } from '@/services/evaluation.service';
import { useToast } from 'vue-toastification';
export default {
    components: {
        DashboardCandidat
    },
    data() {
        return {
            titre:'',
            question_actu: '',
            evaluation:{
                titre:'',
                questionnaire:[]
            },
            questionnaire: [],
            reponses: [],
            reponse: '',
            point:1,
            point_invalid:false,
            point_vide:false,
            valid: false,
            cannot_add: false,
            cannot_finish: false,
            is_edit_question :false,
            index_question: null
        }
    },
    methods: {
        add_reponse() {
            if (this.reponse == '') {
                this.cannot_add = true
            } else {
                this.cannot_add = false
                this.reponses.push({ reponse: this.reponse, valid: this.valid })
                this.reponse = '',
                    this.valid = false
            }

        },
        add_question() {
            if (this.question_actu == '') {
                this.cannot_finish = true
            } else if(this.point_is_valid()){

                this.cannot_finish = false

                if (this.reponse != '') {
                    this.reponses.push({ reponse: this.reponse, valid: this.valid })
                }

                // on verifie qu'au moins une reponse a été cochée vrai
                let valid_question
                let valids = []
                this.reponses.forEach(reponse => {
                    valids.push(reponse.valid)
                }); 
                valid_question = valids.some(el => el === true)

                // si au moins une reponse est vrai alors 
                if (valid_question) {
                    const question = {
                        question: this.question_actu,
                        points:this.point,
                        reponses: this.reponses
                    }


                    if(this.is_edit_question){
                        this.questionnaire[this.index_question] = question
                        this.is_edit_question = false
                        this.index_question = null

                    }else{
                        this.questionnaire.push(question)
                    }
                    this.question_actu = ''
                    this.reponses = []
                    this.reponse = ''
                    this.point = 1
                    this.valid = false
                } else {
                    this.reponse = ''
                    this.valid = false
                    const toast = useToast()
                    toast.error('Au moins une réponse doit être vraie')
                }

            }


        },
        delete_question(index){
            this.questionnaire.splice(index,1)
        },
        edit_question(index){
            let question = this.questionnaire[index]
            this.question_actu=question.question
            this.reponses = question.reponses
            this.point=question.points
            this.is_edit_question=true,
            this.index_question = index
        },
        point_is_valid(){
            if(this.point ==''){
                this.point_vide = true
                return false
            }
            if (this.point < 1 || this.point > 50){
                this.point_invalid=true
                return false
            }else{
                this.point_vide=false
                this.point_invalid=false
                return true
            }
        },
        add_evaluation(){
            if(this.titre ==''){
                const toast = useToast()
                    toast.error('Veuillez donner un titre a l\'évaluation')
            }else if(this.questionnaire.length == 0){
                const toast = useToast()
                    toast.error('Votre questionnaire est vide')
            }else{
                this.evaluation.questionnaire = this.questionnaire
                this.evaluation.titre = this.titre
                Evaluation.create_evaluation(this.evaluation).then(
                   res=>{
                    this.$swal.fire(
                        {title:'Evaluation créée avec succès',icon:'success'}
                        ).then(isConfirmed=>{
                            this.$router.push({name:'EvaluationView'})
                        })

                   
                   }
                ).catch(
                    err=>{
                        this.$swal.fire(
                    'Opération échouée!',
                    'L\'assignation a échouée',
                     'error'
                )
                        console.log(err)}
                )
            }
        }
    }
}
</script>

<style>
.evalu {
    color: #1D4851;
}

.ques:hover {
    background-color: #60696923;
}
</style>