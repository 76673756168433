<template>
       <div class="nav-list d-none d-md-block">
        <div class="pcoded-inner-navbar main-menu">
            <ul class="pcoded-item pcoded-left-item sidebar" style="margin-top: 20%;">
                <li class=" ">
                    <router-link class="waves-effect waves-dark" :to="{name:'dashboard_recruteur'}">
                        <span class="pcoded-micon">
                        </span>
                        <i class="fas fa-tachometer-alt"></i><span class="tout">Tableau de Bord</span>
                    </router-link>
                </li>
                <li class="">
                        <router-link class="waves-effect waves-dark "  :to="{name:'profil_recruteur'}">
                          <span class="pcoded-micon">
                        </span>
                        <i class="fas fa-user"></i><span class="tout" >Mon profil</span>
                        </router-link>
                </li>
                <!-- <li class=" ">
                    <a href="#" class="waves-effect waves-dark">
                        <span class="pcoded-micon">
                        </span>
                        <i class="fas fa-user-plus"></i><span class="tout">Mes Recrutements</span>
                    </a>
                </li> -->
                <!-- <li class=" ">
                    <router-link :to="{name:'EvaluationView'}" class="waves-effect waves-dark">
                        <span class="pcoded-micon">
                        </span>
                        <i class="fas fa-tasks"></i><span class="tout">Mes évaluations</span>
                    </router-link>
                </li> -->
                <!-- <li class=" ">
                    <router-link :to="{name:'EntretienView'}" class="waves-effect waves-dark ">
                        <span class="pcoded-micon ">
                        </span>
                        <i class="fas fa-calendar-check"></i><span class="tout">Mes entretiens</span>
                    </router-link>
                </li>
                 -->
                
            </ul>
        </div>
    </div>
</template>


<style>
.fas{
     margin-right: 6%;
 }
 .tout{
     font-size: 17px;
     font-weight: bold;
 }
</style>