import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });
const store = createStore({
    state(){
        return{
            abonne:false,
            token:null,
            user:null
        }
    },
    getters:{
        getToken(state){
            return state.token
        },
        getRole(state){
            if(state.user){
                return state.user.role
            }
        },
        getUser(state){
            if (state.user !=null){
                return state.user
            }else{
                return null
            }
            
        },
        getAbonne(state){
            return state.abonne
        }
        
    },
    mutations:{
        SET_USER(state,user){
            state.user = user
        },
        SET_TOKEN(state,token){
            state.token = token
        },
        SET_ABONNE(state,isabonne){
            state.abonne = isabonne
        },
        LOGOUT(state){
            state.token = null
            state.user=null
            ls.clear()
        }
    },
    plugins: [
        createPersistedState({
          storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
          },
        }),
      ],
})

export default store