import Axios from "./caller.service";
import store from "@/store/index";

const TOKEN = store.getters.getToken;
const USER_ROLE = store.getters.getRole;
const USER = store.getters.getUser;
let register = (credentials) => {
  return Axios.post("api/register/", {
    email: credentials.email,
    password: credentials.password,
    role: credentials.role,
  });
};

let login = (credentials) => {
  return Axios.post("/api/token/", {
    email: credentials.email,
    password: credentials.password,
    //inutile
    // role:credentials.role
  });
};

let logout = () => {
  store.commit("SET_TOKEN", null);
  store.commit("SET_USER", null);
};

let saveUser = (user) => {
  store.commit("SET_USER", user);
  if (user.role == "recruteur") {
    store.commit("SET_ABONNE", user.abonne);
  }
};

let saveToken = (token) => {
  store.commit("SET_TOKEN", token);
};

let sendForgotPasswordMail = (credentials) => {
  return Axios.post("api/password-reset/", { email: credentials.email });
};

let reset_password = (payload) => {
  const url = `api/password-reset-confirm/${payload.user_id}/${payload.token}/`;
  return Axios.post(url, { password: payload.password });
};

let update_profil_recruteur = (data) => {
  return Axios.patch(`api/recruteur/${USER.id}/update/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
let update_profil_candidat = (id, data) => {
  return Axios.patch(`api/candidat/${id}/update/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
let verify_profil_candidat = (user_id, offre_id) => {
  return Axios.get(`user/check/${user_id}/offre/${offre_id}/`);
};
let get_profil_recruteur = () => {
  return Axios.get(`api/recruteur/${USER.id}`);
};
let get_infos_candidat = (id_emploie, id) => {
  return Axios.get(`api/get_candidatures/`, {
    params: { offre_emploi: id_emploie, id: id },
  });
};
let get_profil_candidat = (id) => {
  return Axios.get(`api/candidat/${id}`);
};

let get_info_candidat = (id) => {
  return Axios.get(`api/candidat/${id}/info/`);
};

let get_info_recruteur = (id) => {
  return Axios.get(`api/recruteur/${id}/info/`);
};

let suscribe = () => {
  return Axios.put("api/recruteur/subscribe/");
};
export const accountService = {
  login,
  logout,
  saveUser,
  saveToken,
  register,
  TOKEN,
  USER_ROLE,
  get_infos_candidat,
  USER,
  verify_profil_candidat,
  sendForgotPasswordMail,
  reset_password,
  update_profil_recruteur,
  get_profil_recruteur,
  get_profil_candidat,
  update_profil_candidat,
  get_info_candidat,
  get_info_recruteur,
  suscribe,
};
