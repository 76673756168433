<template>
  <DashboardRecruteur>
    <div class="row">
      <div class="col">
        <div class="card lescard" v-if="infosCandidat">
          <div class="card-header moncard">Informations du candidat</div>
          <div class="card-body">
            <div class="d-flex flex-column flex-md-row justify-content-around">
              <img class="mb image" :src="photo" alt="image candidat" />
              <div class="d-flex flex-column">
                <a :href="lien_cv"
                  ><button class="btn btn-primary m-2">
                    Consulter le CV
                  </button></a
                >
                <a :href="lien_lettre"
                  ><button class="btn btn-primary m-2">
                    Consulter la Lettre de motivation
                  </button></a
                >
              </div>
            </div>

            <table class="table table-borderless"  v-if="infosCandidat?.candidat">
              <tbody>
                <tr  v-if="infosCandidat.candidat?.nom">
                  <td>Nom</td>
                  <td>{{ infosCandidat.candidat?.nom }}</td>
                </tr>
                <tr v-if="infosCandidat.candidat?.prenoms">
                  <td>Prénom(s)</td>
                  <td>{{ infosCandidat.candidat?.prenoms }}</td>
                </tr>
                 <tr v-if="infosCandidat.candidat?.telephone">
                  <td>Téléphone</td>
                  <td>{{ infosCandidat.candidat?.telephone }}</td>
                </tr>
                <tr v-if="infosCandidat.candidat?.email">
                  <td>Email</td>
                  <td>{{ infosCandidat.candidat?.email }}</td>
                </tr>
                <tr v-if="infosCandidat.candidat?.sexe" >
                  <td>Sexe</td>
                  <td>{{ sexe }}</td>
                </tr>
               <tr v-if="infosCandidat.candidat?.date_naissance " >
                  <td>Date de naissance</td>
                  <td>{{ infosCandidat.candidat?.date_naissance }}</td>
                </tr>
                <tr v-if="infosCandidat.candidat?.adresse">
                  <td>Adresse</td>
                  <td>{{ infosCandidat.candidat?.adresse }}</td>
                </tr>
                <tr v-if="infosCandidat.candidat?.pays">
                  <td>Pays d'origine</td>
                
                  <td>{{ infosCandidat.candidat?.pays }}</td>
                </tr>
                <tr v-if="infosCandidat.candidat?.niveau_etude" >
                  <td>Niveau d'étude</td>
                  <td>{{ infosCandidat.candidat?.niveau_etude }}</td>
                </tr>
              </tbody>
            </table>
            <div class="card" v-if="infosCandidat.candidat?.competences">
              <div class="card-header moncard">Compétences</div>
              <div class="card-body">
                <ul>
                {{infosCandidat.candidat?.competences}}
                </ul>
              </div>
            </div>
          
<!-- 
            <div
              v-for="(exp, index) in candidat.experience_professionnelle"
              :key="index"
            >
              <div class="card">
                <div class="card-header moncard">
                  Expérience professionnelle n°{{ index + 1 }}
                </div>
                <div class="card-body">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td>Nom de l'entreprise</td>
                        <td>{{ exp.nom_entreprise }}</td>
                      </tr>
                      <tr>
                        <td>Date de début</td>
                        <td>{{ exp.date_debut }}</td>
                      </tr>
                      <tr>
                        <td>Date de fin</td>
                        <td>{{ exp.date_fin }}</td>
                      </tr>
                      <tr>
                        <td>Lieu</td>
                        <td>{{ exp.lieu }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="ml-2">
                    <div class="description_title">
                      Description de l'experience
                    </div>
                    <div>
                      <p>{{ exp.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </DashboardRecruteur>
</template>

<script>
import DashboardRecruteur from "@/views/recruteur/DashboardView.vue";
import { accountService, infoService } from "@/services";
export default {
  components: { DashboardRecruteur },
  props: {
    id: {
      type: Number,
    },
  },
  mounted() {
    accountService
      .get_infos_candidat(this.$route.query.id_emploie, this.$route.params.id)
      .then((res) => {
        this.infosCandidat = res.data.results[0];
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      infosCandidat: null,
      candidat: {
        cv: {},
        lettre_motivation: {},
      },
      niv_etude: {},
    };
  },
  computed: {
    sexe() {
      return this.infosCandidat.candidat.sexe == "M" ? "Homme" : "Femme";
    },
    photo() {
      return this.candidat.photo != null
        ? `${process.env.API_URL}${this.candidat.photo}`
        : "../../assets/img/default_user.png";
    },
    lien_cv() {
      return this.infosCandidat.cv.cv;
    },
    lien_lettre() {
      return this.infosCandidat?.lettre?.lettre;
    },
  },
  methods: {},
};
</script>
<style scoped>
/* Cible le premier td de chaque tr */
td:first-child,
.description_title {
  font-size: 20px;
  font-weight: bold;
  color: #1d4855;
}

.mb {
  margin-bottom: 5%;
}

.moncard {
  background-color: #1d4855;
  color: white;
  font-weight: bold;
  font-size: 20px;
}

li {
  list-style: none;
}

.image {
  width: 200px;
  height: 150px;
}
</style>
