import Axios from "./caller.service";

let my_documents_cv = (id) => {
  return Axios.get("api/cv/", {
    params: { candidat: id },
  });
};
let my_documents_motivation = (id) => {
  return Axios.get("api/lettre/", {
    params: { candidat: id },
  });
};

let save_document_cv = (data) => {
  return Axios.post("api/cv/", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
let save_document_motivation = (data) => {
  return Axios.post("api/lettre/", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

let update_candidat_doc = (data) => {
  return Axios.put("api/update_candidat_doc/", data);
};

let delete_document = (id) => {
  return Axios.delete(`api/cv/${id}`);
};
let delete_document_motivation = (id) => {
  return Axios.delete(`api/lettre/${id}`);
};

export const Document = {
  my_documents_cv,
  save_document_cv,
  update_candidat_doc,
  delete_document,
  save_document_motivation,
  my_documents_cv,
  my_documents_motivation,
  delete_document_motivation,
};
