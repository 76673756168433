<template>
  <DashboardRecruteur>
    <div class="row">
      <div class="col-12 col-xl-4 col-md-4">
        <div class="card comp-card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-b-25 perso-text">Total (En cours ou Archivés)</h6>
                <h3 class="f-w-700 text-c-blue perso-text">{{ encours }}</h3>
              </div>
              <div class="col-auto">
                <i class="fas fa-briefcase bg-c-blue"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-4 col-md-4">
        <div class="card comp-card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-b-25 perso-text">Recrutements terminés</h6>
                <h3 class="f-w-700 text-c-green perso-text">
                  {{ recrutement_termines }}
                </h3>
              </div>
              <div class="col-auto">
                <i class="fas fa-check-circle bg-c-green"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-4 col-md-4">
        <div class="card comp-card">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-b-25 perso-text">Candidatures en cours</h6>
                <h3 class="f-w-700 text-c-yellow perso-text">
                  {{ candidatures_en_cours }}
                </h3>
              </div>
              <div class="col-auto">
                <i class="fas fa-address-card bg-c-yellow"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h5>Recrutements</h5>

        <div class="row mt-4 j align-items-center">
          <div class="col-sm-6">
            <select class="form-select w-50" @change="get_offre($event)">
              <option value="en cours">En cours</option>
              <option value="archive">Archivés</option>
            </select>
          </div>
          <div class="col-sm-6 text-end">
            <router-link :to="{ name: 'add_offre' }">
              <button class="btn mybtn my-2">nouveau recrutement</button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row card-block">
        <div class="col-md-12">
          <ul class="list-view" v-if="offre_emplois.length !== 0">
            <li v-for="(item, index) in offre_emplois" :key="index">
              <div class="card offre-card list-view-media">
                <div class="card-block">
                  <div class="media">
                    <a class="media-left" href="#">
                      <img
                        class="media-object card-list-img img-fluid"
                        style="width: 100px; height: 100px"
                        :src="
                          item?.image
                            ? item.image
                            : '../../assets/img/img_cv.png'
                        "
                        alt="entreprise image"
                      />
                    </a>
                    <div class="media-body">
                      <div @click="manage_offre(item.id)">
                        <div
                          class="col-xs-12 d-sm-flex justify-content-between"
                        >
                          <div>
                            <h5 class="d-inline-block f-w-600">
                              {{ item.poste }}
                            </h5>
                            <label
                              class="mx-1 label label-info"
                              v-if="
                                item.type_contrat != '' &&
                                item.type_contrat != null
                              "
                              >{{ item.type_contrat }}</label
                            >
                          </div>
                          <div>
                            <div
                              class="small f-w-500 mt-3 mt-sm-0"
                              v-if="item.date_creation"
                            >
                              <i class="fa fa-calendar-alt"></i> Créé le
                              {{ getDate(item.date_creation) }}
                            </div>

                            <div
                              class="small f-w-500 mt-3 mt-sm-0"
                              v-if="item.date_publication"
                            >
                              <i class="fa fa-calendar-alt"></i> Publié le
                              {{ getDate(item.date_publication) }}
                            </div>
                          </div>
                        </div>
                        <div class="f-13 text-muted m-b-15">
                          {{ item.nom_entreprise }}
                        </div>
                        <p></p>
                        <div class="h6">Compétences requises:</div>
                        <span>{{ item.competences }}, </span>
                        <div class="h6 my-1">
                          Niveau d'étude requis:<span
                            class="mx-2 text-c-blue"
                            >{{ item.niveau_etude }}</span
                          >
                        </div>
                        <div class="h6 my-2">
                          Date de clôture :<span class="mx-2 text-c-red">
                            {{ getDate(item.date_limite) }}</span
                          >
                        </div>
                      </div>
                      <div class="float-end">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          @click="pushOffre(item.id)"
                        >
                          Publier
                        </button>
                        <button
                          @click="edit_offre(item.id)"
                          data-toggle="tooltip"
                          title="modifier"
                          class="btn btn-facebook btn-primary btn-mini waves-effect waves-light mx-2"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <button
                          @click="delete_offre(item.id, index)"
                          data-toggle="tooltip"
                          title="supprimer"
                          class="btn btn-facebook btn-danger btn-mini waves-effect waves-light mx-2"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <p v-else class="text-center">Aucun recrutement en cours</p>
        </div>
      </div>
    </div>
  </DashboardRecruteur>
</template>

<script>
import moment from "moment";
import { Candidature } from "@/services/candidature.service";
import DashboardRecruteur from "./DashboardView.vue";
import { OffreEmploi } from "@/services/offre_emploi.service";
import Swal from "sweetalert2";

export default {
  components: { DashboardRecruteur },
  async mounted() {
    this.get_offre_non_archive();

    OffreEmploi.recruteur_statistique(this.user.id).then((res) => {
      // this.recrutement_termines = res.data.recrutement_termines
      this.candidatures_en_cours = res.data.nombre_de_candidatures;
    });
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      offre_emplois: [],
      recrutement_termines: 0,
      candidatures_en_cours: 0,
      type: "en cours",
    };
  },
  methods: {
    async get_offre_non_archive() {
      await OffreEmploi.get_recruteur_offres(this.user.id, false)
        .then((res) => (this.offre_emplois = res.data.results))
        .catch((err) => console.log(err));
    },
    async get_offre_archive() {
      await OffreEmploi.get_recruteur_offres(this.user.id, true)
        .then((res) => (this.offre_emplois = res.data.results))
        .catch((err) => console.log(err));
    },
    edit_offre(offre_id) {
      this.$router.push({ name: "edit_offre", params: { id: offre_id } });
    },
    delete_offre(offre_id, index) {
      Swal.fire({
        title: "Êtes-vous sûr(e) ?",
        text: "Ce recrutement sera définitivement supprimé",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          OffreEmploi.delete_offre(offre_id)
            .then((res) => {
              this.offre_emplois.splice(index, 1);
              Swal.fire(
                "Supprimé!",
                "Le recrutement a bien été supprimé",
                "success"
              );
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Des candidatures sont en cours pour ce recrutement!",
              });
            });
        }
      });
    },
    manage_offre(offre_id) {
      this.$router.push({ name: "manage_offre", params: { id: offre_id } });
    },
    getDate(date) {
      return new Date(date).toLocaleString("fr-FR");
    },
    get_offre(e) {
      if (e.target.value == "en cours") {
        this.get_offre_non_archive();
      } else {
        this.get_offre_archive();
      }
    },
    pushOffre(id) {
      this.$swal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: `Voulez-vous vraiment publier cette offre `,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, Publier!",
          cancelButtonText: "Quitter",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Candidature.closeOffre(id, {
              is_published: true,
              date_publication: new Date().toISOString().slice(0, -5) + "Z",
            })
              .then((res) => {
                this.$swal.fire({
                  title: `Offre d'emploie publiée`,
                  icon: "success",
                });
                this.$router.push({
                  name: "OffreDisponible",
                });
              })
              .catch((err) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Error d'opération !",
                });
                console.log(err);
              });
          }
        });
    },
  },
  computed: {
    userConnected() {
      return this.$store.getters.getUser;
    },
    images() {
      return this.offre_emplois?.map((offre) =>
        offre.image != null
          ? `${process.env.API_URL}${offre.image}`
          : `${process.env.API_URL}/media/images/defaut.jpg`
      );
    },
    encours() {
      return this.offre_emplois.length;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.perso-text {
  font-family: "Roboto", sans-serif;
}

.offre-card:hover {
  transform: scale(1.03);
  transition: 200ms;
  cursor: pointer;
}

.offre-card {
  transition: 200ms;
}
@media only screen and (max-width: 370px) {
  .media {
    display: block !important;
  }
}
</style>
