<template>
  <GuestLayout>
    <template #navbar>
      <div v-if="this.$store.getters.getUser != null">
        <NavbarUser :Sidebar="false"></NavbarUser>
      </div>
    </template>
    <section :id="section_id" class="d-flex align-items-center">
      <div class="container d-flex flex-column" data-aos="fade-up">
        <section class="container-fluid">
          <div class="row">
            <div class="col-md-8">
              <div class="card sale-card">
                <div class="card-header">
                  <div class="card-header-right publication">
                    <i class="fas fa-calendar-alt"></i> Publiée le
                    {{ getDate(offre_emploi?.date_publication) }}
                  </div>
                </div>

                <div class="card-body">
                  <div class="col-12">
                    <p class="socie text-center">
                      {{ offre_emploi?.poste }}
                    </p>
                    <p class="descrept">
                      <i class="fas fa-tag mx-2"></i> Description de l'offre
                    </p>

                    <div v-if="offre_emploi?.description == ''">
                      <p class="lorem">
                        Aucune description fourni par le recruteur
                      </p>
                    </div>
                    <p class="lorem" v-html="offre_emploi?.description"></p>
                  </div>
                </div>
                <div class="card-block">
                  <div class="row">
                    <div class="col-sm-8">
                      <div
                        id="allocation-map"
                        class="chart-shadow"
                        style="height: 215px"
                      ></div>
                    </div>
                    <div class="col-sm-4">
                      <div
                        id="allocation-chart"
                        class="chart-shadow"
                        style="height: 215px"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" style="margin-top: 2%">
              <div class="card prod-p-card card-white">
                <div class="card-body">
                  <label class="badge badge-info" style="margin-left: 70%">{{
                    offre_emploi?.type_contrat
                  }}</label>
                  <div class="card1">
                    <p class="commun">
                      <i class="fas fa-map-marker-alt"></i
                      ><span class="com">{{
                        offre_emploi?.localisation ?? "non spécifiée"
                      }}</span>
                    </p>

                    <p class="exp" v-if="offre_emploi?.salaire">
                      <i class="fas fa-money-bill-alt"></i>
                      <span class="com"
                        >Salaire {{ offre_emploi?.salaire }} fcfa</span
                      >
                    </p>
                    <p class="exp d-flex" v-if="offre_emploi?.niveau_etude">
                      <i class="fas fa-certificate"></i>
                      <span class="com"
                        >Niveau d'étude requis :
                        {{ offre_emploi?.niveau_etude }}
                      </span>
                    </p>
                    <div class="exp d-flex align-items-center">
                      <i class="fas fa-calendar-alt"></i>
                      <p class="co">
                        Expire le {{ getDate(offre_emploi?.date_limite) }}
                      </p>
                    </div>
                  </div>
                  <div class="col-auto"></div>
                </div>
              </div>
              <div class="card prod-p-card card-white">
                <div class="card-body">
                  <div class="card2">
                    <p class="comp">
                      <i class="fas fa-star"></i
                      ><span class="com">Compétences requises</span>
                    </p>
                    <ul class="competences text-md-start">
                      <span>{{ offre_emploi?.competences }}</span>
                    </ul>
                    <p class="lang">
                      <i class="fas fa-globe"></i
                      ><span class="com">Langues requises</span>
                    </p>
                    <ul class="langue text-md-start">
                      <span>
                        {{ offre_emploi?.langue_requise ?? "non spécifiée" }}
                      </span>
                    </ul>
                    <!-- <div
                      v-if="
                        (offre_emploi?.is_Email &&
                          this.$store.getters.getRole != 'recruteur') ||
                        (offre_emploi?.is_Whatsapp &&
                          this.$store.getters.getRole != 'recruteur')
                      "
                    >
                      <label for="">Postuler par :</label>
                      <div class="d-flex justify-content-between">
                        <button
                          class="mybtn btn-sm btn"
                          @click="sendWhatsap('email')"
                          v-if="
                            this.$store.getters.getRole != 'recruteur' &&
                            offre_emploi?.is_Email
                          "
                        >
                          <span class="postuler">Email</span>
                        </button>
                        <button
                          class="mybtn btn-sm btn"
                          @click="sendWhatsap('whatsap')"
                          v-if="
                            this.$store.getters.getRole != 'recruteur' &&
                            offre_emploi?.is_Whatsapp
                          "
                        >
                          <span class="postuler">Whatsap</span>
                        </button>
                      </div>
                    </div> -->

                    <button
                      class="mybtn w-100 btn-sm btn"
                      @click="openChoiceFunction"
                      v-if="this.$store.getters.getRole != 'recruteur'"
                    >
                      <span class="postuler"
                        >Postuler
                        <span v-if="offre_emploi?.is_Whatsapp">
                          par whatsap</span
                        ></span
                      >
                    </button>
                  </div>
                  <div class="col-auto"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="modal fade"
          :class="{ 'd-block': open, show: open }"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Envoie de mes documents
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  @click="open = false"
                ></button>
              </div>
              <form @submit.prevent="postuler">
                <div class="modal-body bg-white">
                  <div class="mb-2">
                    <div class="form-outline">
                      <label
                        for="cv"
                        class="block"
                        style="color: #1d4851; font-weight: bold"
                        >Curriculum Vitae
                        <span style="color: rgb(233, 105, 1)">*</span>
                      </label>
                    </div>
                    <div class="mb-2">
                      <div class="form-outline">
                        <select
                          class="form-select"
                          v-model="documents.cv"
                          required
                        >
                          <option
                            :value="doc.id"
                            v-for="doc in document_cv"
                            :key="doc.id"
                          >
                            {{ doc.nom }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <div class="form-outline">
                      <label
                        for="cv"
                        class="block"
                        style="color: #1d4851; font-weight: bold"
                        >Lettre de motivation
                        <span style="color: rgb(233, 105, 1)">*</span>
                      </label>
                    </div>
                    <div class="mb-2">
                      <div class="form-outline">
                        <select
                          class="form-select"
                          v-model="documents.motivation"
                          required
                        >
                          <option
                            :value="doc.id"
                            v-for="doc in document_motivation"
                            :key="doc.id"
                          >
                            {{ doc.nom }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="reset"
                    class="btn ms-3 btn-secondary"
                    @click="open = false"
                  >
                    Fermer
                  </button>
                  <button type="submit" class="btn btn-primary">
                    <div
                      class="spinner-grow text-light"
                      role="status"
                      v-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Valider
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          :class="{ 'd-block': openChoice, show: openChoice }"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Postuler par</h5>
                <button
                  type="button"
                  class="btn-close"
                  @click="openChoice = false"
                ></button>
              </div>
              <form>
                <div class="modal-body bg-white">
                  <div class="mb-2">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDisabled"
                        id="flexRadioDisabled"
                        @click="sendWhatsap('email')"
                      />
                      <label class="form-check-label" for="flexRadioDisabled">
                        Email
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDisabled"
                        id="flexRadioCheckedDisabled"
                        @click="sendWhatsap('whatsap')"
                      />
                      <label
                        class="form-check-label"
                        for="flexRadioCheckedDisabled"
                      >
                        Whatsap
                      </label>
                    </div>
                  </div>
                </div>
                <div class="modal-footer justify-content-between">
                  <button
                    type="reset"
                    class="btn ms-3 btn-secondary"
                    @click="openChoice = false"
                  >
                    Fermer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </GuestLayout>
</template>
<script>
import "@/assets/js/main.js";
import GuestLayout from "@/components/GuestLayout.vue";
import NavbarUser from "@/components/NavbarUser.vue";
import { OffreEmploi } from "@/services/offre_emploi.service";
import { accountService } from "@/services";
import { Document } from "@/services/document.service";
import moment from "moment";

export default {
  components: { GuestLayout, NavbarUser },
  props: ["id"],
  data() {
    return {
      openChoice: false,
      loading: false,
      mydata: new FormData(),
      open: false,
      documents: {
        cv: null,
        motivation: null,
      },
      document_cv: [],
      document_motivation: [],
      offre_emploi: null,
      recruteur: {},
      byWhatsap: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    section_id() {
      if (this.$store.getters.getUser == null) {
        return "hero-rbk";
      } else {
        return "";
      }
    },
  },
  async mounted() {
    await OffreEmploi.get_offre(this.id)
      .then((res) => {
        this.offre_emploi = res.data;
      })
      .catch((err) => console.log(err));

    if (this.user.role == "candidat") {
      await Document.my_documents_cv(this.user?.id)

        .then((res) => {
          this.document_cv = res.data.results;
        })
        .catch((err) => console.log(err));
      await Document.my_documents_motivation(this.user?.id)

        .then((res) => {
          this.document_motivation = res.data.results;
        })
        .catch((err) => console.log(err));
    }
  },
  methods: {
    openChoiceFunction() {
      if (this.offre_emploi?.is_Email && this.offre_emploi?.is_Whatsapp) {
        this.openChoice = true;
      } else if (this.offre_emploi?.is_Whatsapp) {
        this.sendWhatsap("whatsap");
      } else if (this.offre_emploi?.is_Email) {
        this.sendWhatsap("email");
      } else {
        this.sendWhatsap();
      }
    },
    postuler() {
      this.loading = true;
      if (this.$store.getters.getUser == null) {
        this.$router.push({ name: "login" });
      } else {
        this.mydata.append("cv", this.documents.cv);
        this.mydata.append("lettre", this.documents.motivation);
        this.mydata.append("offre_emploi", this.$route.params.id);
        this.mydata.append("candidat", this.user.id);
        if (this.byWhatsap == "watsap") {
          this.mydata.append("is_Whatsapp", true);
        } else if (this.byWhatsap == "email") {
          this.mydata.append("is_Email", true);
        }
        const url = `https://wa.me/+229${this.offre_emploi.telephone_destination}?text=Candidature`;
        OffreEmploi.postuler(this.mydata)
          .then((res) => {
            this.open = false;
            console.log(this.byWhatsap);
            if (this.byWhatsap) {
              window.open(url);
            }
            this.loading = false;

            // this.sendMAil()
            this.$swal.fire(
              "Candidature Créée",
              "Vous avez postulé à cette offre",
              "success"
            );
          })
          .catch((err) => {
            this.loading = false;
            console.log("err", err);
            if (err.response?.data?.status == "400") {
              this.$swal.fire(
                "Operation échouée",
                `${err?.response?.data.detail}`,
                "warning"
              );
            } else {
              this.$swal.fire(
                "Opération échouée",
                "Une erreur s'est produite",
                "error"
              );
            }
          });
      }
    },
    getDate(date) {
      return new Date(date).toLocaleString("fr-FR");
    },

    sendWhatsap(data) {
      if (!this.user) {
        this.$router.push({ name: "login" });
        return;
      }
      if (data == "whatsap") {
        this.byWhatsap = data;
      }
      if (
        this.document_cv.length == 0 &&
        this.document_motivation.length == 0
      ) {
        this.$swal.fire(
          "Non autorisée",
          "Veuillez ajouter un cv et une lettre de motivation",
          "warning"
        );
      } else {
        accountService
          .verify_profil_candidat(this.user.id, this.$route.params.id)
          .then((res) => {
            this.open = true;
          })
          .catch((err) => {
            if (err.response.status === 400) {
              this.$swal.fire(
                "Operation échouée",
                `${err.response.data.cause}`,
                "warning"
              );
            }
          });
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}

#R_btn_rechercher {
  background-color: #1d4851;
  color: white;
  width: 150px;
  height: 50px;
}

#R_btn_rechercher:hover {
  background-color: #cc5500;
}

#R_btn_connexion {
  background-color: #1d4851;
  color: white;
}

#R_btn_connexion:hover {
  background-color: #cc5500;
}

#Footer1 {
  margin-left: 80%;
  margin-top: -30%;
}

#R-footer {
  margin-left: 20%;
}

.card {
  margin-top: 5%;
}

.logo {
  width: 20%;
  height: 20%;
  margin-top: 10%;
  margin-left: 5%;
  border-radius: 100%;
  border: 1px;
}

.metier {
  color: #00080a;
  font-style: italic;
  font-size: 15px;
  font-weight: bold;
}

.socie {
  font-weight: bold;
  font-size: 25px;
  color: #1d4851;
}

.offre {
  font-size: 15px;
  font-weight: bold;
  color: #1c5e74;
  margin-left: 70%;
}

.logo {
  width: 15%;
  margin-top: 2%;
}

.descrept {
  font-weight: bold;
  font-size: 20px;
  color: #1d4851;
  background-color: #d1dfe3;
}

.lorem {
  font-size: 20px;
  text-align: start;
}

.publication {
  font-style: italic;
  font-weight: bold;
}

.commun {
  font-size: 15px;
  color: #1d4851;
  font-weight: bold;
}

.btn {
  background-color: #1d4851;
  width: 30%;
}

.btn:hover {
  background-color: #cc5500;
}

.postuler {
  color: white;
}

.img {
  width: 721px;
  margin-top: 1%;
  height: 250px;
  margin-right: 2%;
  border-radius: 2%;
}

.card1 {
}

.lang {
  font-size: 15px;
  color: #1d4851;
  font-weight: bold;
  margin-right: 30%;
}

.comp {
  font-size: 15px;
  color: #1d4851;
  font-weight: bold;
  margin-right: 15%;
}

.exp {
  margin-right: 20%;
  font-size: 15px;
  color: #1d4851;
  font-weight: bold;
  margin-right: 15%;
}

/* .com {
  margin-left: 5%;
} */

.competences {
  list-style-type: circle;
  color: #1d4851;
  font-weight: bold;
}

.co {
  color: rgb(241, 105, 105);
}

.langue {
  list-style-type: circle;
  color: #1d4851;
  font-weight: bold;
}
.modal-footer {
  flex-wrap: initial !important;
}
.modal.fade {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
