<template>
        <DashboardLayout>
            <template #navbar>
                <NavbarUser></NavbarUser>
            </template>
           
            <template #sidebarNav>
                <SidebarRecruteur></SidebarRecruteur>
            </template>
            <slot class="w-md-100"></slot>
        </DashboardLayout>
</template>
<script>
import NavbarUser from '@/components/NavbarUser.vue'
import SidebarRecruteur from '@/components/SidebarRecruteur.vue';
import DashboardLayout from '@/components/DashboardLayout.vue';
export default{
    name:'DashboardRecruteur',
    components:{SidebarRecruteur,DashboardLayout,NavbarUser}
}
</script>