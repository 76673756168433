<template>
<DashboardRecruteur>
  <div>

    <form @submit.prevent="update_profil()" enctype="multipart/form-data">
      <section class="text-center">

        <div class="card mx-4 mx-md-5 shadow-5-strong" style="
                            background: hsla(0, 0%, 100%, 0.8);
                            backdrop-filter: blur(30px);
                            ">
          <div class="card-body py-5 px-md-5">

            <div class="row d-flex justify-content-center">
              <div class="">
                <h2 class="fw-bold mb-5" style="color: #1D4851;">Mon profil</h2>

                <!-- 2 column grid layout with text inputs for the first and last names -->
                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="nom" style="color: #1D4851;">Nom</label>
                      <input v-model="user.nom" type="text" id="nom" class="form-control bg-light" />
                    </div>

                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2" style="color: #1D4851;">Prénom</label>
                      <input v-model="user.prenoms" type="text" id="form3Example2" class="form-control bg-light" />

                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2" style="color: #1D4851;">Email</label>
                      <input v-model="user.email" type="text" id="form3Example2" class="form-control bg-light" disabled />
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2" style="color: #1D4851;">Numéro de
                        téléphone</label>
                      <input v-model="user.telephone" type="number" id="form3Example2" class="form-control bg-light" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2" style="color: #1D4851;">Pays</label>
                      <v-select class="bg-light" v-model="user.pays" :options="pays"
                        placeholder="Sélectionner un pays"></v-select>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2" style="color: #1D4851;">Adresse</label>
                      <input v-model="user.adresse" type="text" id="form3Example2" class="form-control bg-light" />
                    </div>
                  </div>
                </div>
                <div>
                  <select @change="$event => check_is_particulier($event)" id="checker" class="form-select bg-light mb-4"
                    aria-label="Default select example" style="width: 100%; ">
                    <option>Qui êtes-vous?</option>
                    <option value="entreprise">Entreprise</option>
                    <option value="particulier">Particulier</option>
                  </select>
                </div>
                <div v-show="is_particulier">
                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label class="form-label" for="form3Example2" style="color: #1D4851;">Numéro
                          IFU</label>
                        <input v-model="user.numero_ifu" type="text" id="form3Example2" class="form-control bg-light" />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label class="form-label" for="form3Example2" style="color: #1D4851;">Numéro
                          CNSS</label>
                        <input v-model="user.numero_cnss" type="text" id="form3Example2" class="form-control bg-light" />
                      </div>
                    </div>
                  </div>
                  <div class=" mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2"
                        style="color: #1D4851; width: 100%; ">Registre de commerce</label>
                      <input v-model="user.registre_commerce" type="text" id="form3Example2"
                        class="form-control bg-light" />
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2"
                        style="color: #1D4851;width: 100%; ">Secteur d'activité</label>
                      <input v-model="user.secteur_activite" type="text" id="form3Example2"
                        class="form-control bg-light" />
                    </div>
                  </div>
                  <div class="mb-4">
                    <div class="form-outline">
                      <label class="form-label" for=""
                        style="color: #1D4851;width: 100%; ">Description de l'entreprise</label>
                      <textarea v-model="user.description" rows="5" cols="5" class="form-control bg-light"
                        placeholder="Description de votre entreprise"></textarea>
                    </div>
                  </div>
                  <!-- <div class="mb-4">
                    <div class="form-outline">
                      <label class="form-label" for="form3Example2" style="color: #1D4851;">Le logo de
                        votre entreprise</label>
                      <input @change="$event => fileChange($event)" type="file" class="form-control" name="logo"
                      accept="image/jpeg, image/png, image/jpg">
                    </div>
                  </div> -->
                </div>

                <button class="btn btn-lg mb-3"
                  style="background-color: #1D4851; color: white; width: 250px; height: 50px;"
                  type="submit">Sauvegarder</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Section: Design Block -->
    </form>
  </div>
</DashboardRecruteur>
</template>

<script >
import DashboardRecruteur from './DashboardView.vue'
import { infoService, accountService, Countries } from '@/services';

export default {
  components:{DashboardRecruteur},
  data() {
    return {
      is_particulier: false,
      pays: [],
      user: {
        nom: '',
        prenoms: '',
        adresse: '',
        email: '',
        telephone: 0,
        pays: '',
        nom_entreprise: '',
        secteur_activite: '',
        logo: '',
        nombre_enmploye: '',
        description: '',
        is_particulier: '',
        numero_cnss: '',
        numero_ifu: '',
        registre_commerce: ''
      },
      mydata: new FormData()
    }
  },
  computed: {
    userlogo() {
      return `${process.env.API_URL}${this.user.logo}`
    }
  },
  methods: {
    get_profil() {
      accountService.get_profil_recruteur()
        .then(res => {
          console.log(res);
          this.user = res.data  
          const select = document.querySelector("#checker")
          if (this.user.is_particulier == false) {
            select.value = "entreprise"
            this.is_particulier = true
          } else {
            select.value = "particulier"
            this.is_particulier = false
          }

        })
        .catch(err => console.log(err))
    },

    update_profil() {
      this.mydata.append('nom', this.user.nom)
      this.mydata.append('prenoms', this.user.prenoms)
      this.mydata.append('adresse', this.user.adresse)
      this.mydata.append('telephone', this.user.telephone?this.user.telephone:0)
      this.mydata.append('pays', this.user.pays ?? '')
      this.mydata.append('nom_entreprise', this.user.nom_entreprise)
      this.mydata.append('secteur_activite', this.user.secteur_activite)
      this.mydata.append('description', this.user.description)
      this.mydata.append('is_particulier', this.user.is_particulier)
      this.mydata.append('numero_cnss', this.user.numero_cnss)
      this.mydata.append('numero_ifu', this.user.numero_ifu)
      this.mydata.append('registre_commerce', this.user.registre_commerce)
      this.mydata.append('email', this.user.email)

      accountService.update_profil_recruteur(this.mydata)
        .then(res => {
          this.mydata = new FormData()
          this.$store.commit('SET_USER',res.data)
          this.$swal.fire({
            icon: 'success',
            title: 'Profil modifié',
          })
      }
        )
        .catch(err => {
          console.log(err)
          this.$swal.fire({
            icon: 'error',
            title: 'Une erreur s\'est produite',
          })
        })
    },
    check_is_particulier(e) {
      if (e.target.value == "entreprise") {
        this.is_particulier = true
      } else if (e.target.value == "particulier") {
        this.is_particulier = false

      } else {
        this.is_particulier = false
      }
    },
    fileChange(e) {
      const maxSize = 2000000
      const  allowedFileTypes =['image/jpeg', 'image/png', 'image/jpg']
      const file =e.target.files[0]
      if(file.size > maxSize ){
        this.$swal.fire({ title: 'fichier trop volumineux', icon: 'error' })
      }else if(!allowedFileTypes.includes(file.type)){
        this.$swal.fire({ title: 'format de fichier incorrect', icon: 'error' })
        
      }else{
      this.user.logo = e.target.files[0]
      this.mydata.append('logo', e.target.files[0])
      }
      
    }
  },
  mounted() {
    this.get_profil()

    Countries.get_all_countries().then(res => {
      const pays = res.data.map(e => e.translations.fra.common)
      pays.sort((a, b) => {
        return a.localeCompare(b);
      });
      this.pays = pays
    }).catch(err => console.log(err))
  }

}


</script>
<style scoped>


section {
  padding: 0px;
}
</style>