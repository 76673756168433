<template>
  <DashboardCandidat>
    <div>
      <h3>Mes candidatures</h3>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="nav nav-tabs md-tabs col-6 mb-3" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              style="font-weight: bold; font-size: 20px"
              data-toggle="tab"
              href="#home3"
              role="tab"
              >En cours</a
            >
            <div class="slide"></div>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              style="font-weight: bold; font-size: 20px"
              data-toggle="tab"
              href="#profile3"
              role="tab"
              >Archivées</a
            >
            <div class="slide"></div>
          </li>
        </ul>

        <div class="tab-content card-block">
          <div class="tab-pane active" id="home3" role="tabpanel">
            <div class="col-12">
              <div class="card lecard">
                <div class="card-block">
                  <table class="table" v-if="candidatures.length !== 0">
                    <thead>
                      <tr>
                        <th>Nom du poste</th>
                        <th>Nom de l'entreprise</th>
                        <th>Type de contrat</th>
                        <th>Date de postulation</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in candidatures" :key="index">
                        <td>{{ item.offre_emploi?.poste }}</td>
                        <td>{{ item.offre_emploi?.nom_entreprise }}</td>
                        <td>{{ item.offre_emploi?.type_contrat }}</td>
                        <td>{{ getDate(item?.date_postulation) }}</td>

                        <td>
                          <div class="btn-group">
                            <button
                              class="btn btn-sm dropdown-toggle"
                              style="background-color: #1d4851"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Options
                            </button>
                            <ul class="dropdown-menu">
                              <!-- <li>
                                <router-link
                                  class="dropdown-item"
                                  :to="{
                                    name: 'details_candidature',
                                    params: { id: item.id }, query:{id: item.offre_emploi.id}
                                  }"
                                  >voir plus</router-link
                                >
                              </li> -->
                              <li>
                                <router-link
                                  class="dropdown-item"
                                  :to="{
                                    name: 'detail_offre',
                                    params: { id: item.offre_emploi.id },
                                  }"
                                  >Consulter l'offre</router-link
                                >
                              </li>
                              <li>
                                <hr class="dropdown-divider" />
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  style="color: #f53838; cursor: pointer"
                                  @click="annuler_candidature(item.id, index)"
                                  >Annuler ma candidature</a
                                >
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="text-center" v-else>Aucune candidature</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="profile3" role="tabpanel">
            <div class="col-12">
              <div class="card lecard">
                <div class="card-block">
                  <table class="table" v-if="candidatures_archive.length !== 0">
                    <thead>
                      <tr>
                        <th>Nom du poste</th>
                        <th>Nom de l'entreprise</th>
                        <th>Type de contrat</th>
                        <th>Date de postulation</th>
                        <th>Statut</th>

                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in candidatures_archive"
                        :key="index"
                      >
                        <td>{{ item.offre_emploi.poste }}</td>
                        <td>{{ item.offre_emploi.localisation }}</td>
                        <td>{{ item.offre_emploi.type_contrat }}</td>
                        <td>{{ getDate(item.date_postulation) }}</td>
                        <td>{{ item.statut }}</td>

                        <td>
                          <router-link
                            class="dropdown-item text-decoration-underline"
                            :to="{
                              name: 'detail_offre',
                              params: { id: item.offre_emploi.id },
                            }"
                            >Consulter l'offre</router-link
                          >
                          <!-- <div class="btn-group">
                            <button
                              class="btn btn-sm dropdown-toggle"
                              style="background-color: #1d4851"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Options
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <router-link
                                  class="dropdown-item"
                                  :to="{
                                    name: 'details_candidature',
                                    params: { id: item.id }, query:{id: item.offre_emploi.id}
                                  }"
                                  >voir plus</router-link
                                >
                              </li>
                              <li>
                                <router-link
                                  class="dropdown-item btn btn-sm "
                                  :to="{
                                    name: 'detail_offre',
                                    params: { id: item.offre_emploi.id },
                                  }"
                                  >Consulter l'offre</router-link
                                >
                              </li>
                             
                             
                            </ul>
                          </div> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p v-else class="text-center">Aucune candidature</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardCandidat>
</template>

<script>
import DashboardCandidat from "./DashboardView.vue";
import { Candidature } from "@/services/candidature.service";
import moment from "moment";

export default {
  components: { DashboardCandidat },
  mounted() {
    Candidature.my_candidatures(this.user.id, false)
      .then((res) => {
        this.candidatures = res.data.results;
      })
      .catch((err) => console.log(err));

    Candidature.my_candidatures(this.user.id, true)
      .then((res) => {
        this.candidatures_archive = res.data.results;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      candidatures: [],
      candidatures_encours: [],
      candidatures_archive: [],
    };
  },

  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },

  methods: {
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    annuler_candidature(id, index) {
      this.$swal
        .fire({
          title: "Êtes-vous sûr ?",
          text: "Voulez-vous vraiment annuler votre candidature",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, annulé!",
          cancelButtonText: "Quitter",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Candidature.status_candidature(id, { statut: "Annulé(e)" })
              .then((res) => {
                this.candidatures_archive.push(this.candidatures[index]);
                this.candidatures.splice(index, 1);
                this.$swal.fire(
                  "Candidature archivée!",
                  "Votre candidature a bien été annulée",
                  "success"
                );
              })
              .catch((err) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "la candidature n'a pas pu etre annulée !",
                });
                console.log(err);
              });
          }
        });
    },
    restore(id, index) {
      this.$swal
        .fire({
          title: "Êtes-vous sûr?",
          text: "Voulez-vous vraiment restaurer votre candidature ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, restaurer!",
          cancelButtonText: "Quitter",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Candidature.status_candidature(id, { is_archive: false })
              .then((res) => {
                this.candidatures.push(this.candidatures_archive[index]);
                this.candidatures_archive.splice(index, 1);
                this.$swal.fire(
                  "Candidature restaurée!",
                  "Votre candidature a bien été restorée",
                  "success"
                );
              })
              .catch((err) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "la candidature n'a pas pu etre archivé !",
                });
                console.log(err);
              });
          }
        });
    },
  },
};
</script>
<style scoped>
.lecard {
  margin-top: 1%;
  margin-left: 1%;
  width: 900px;
}

.btn {
  background-color: rgb(232, 85, 85);
  color: white;
}

.infocandidat {
  color: #1d4851;
  font-weight: bold;
  font-size: 20px;
}
</style>
