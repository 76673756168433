<template>
  <DashboardCandidat>
    <div>
      <form @submit.prevent="update_profil()" enctype="multipart/form-data">
        <section class="text-center">
          <div
            class="card mx-4 mx-md-5 shadow-5-strong"
            style="background: #ffffffcc; backdrop-filter: blur(30px)"
          >
            <div class="card-body py-5 px-md-5">
              <div class="row d-flex justify-content-center">
                <div class="col-lg-8">
                  <h2 class="fw-bold mb-5" style="color: #1d4851">
                    Mon profil
                  </h2>

                  <!-- 2 column grid layout with text inputs for the first and last names -->
                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="nom"
                          style="color: #1d4851"
                          >Nom</label
                        >
                        <input
                          v-model="user.nom"
                          required
                          type="text"
                          id="nom"
                          class="form-control bg-light"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                          >Prénom</label
                        >
                        <input
                          v-model="user.prenoms"
                          required
                          type="text"
                          id="form3Example2"
                          class="form-control bg-light"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                          >Email</label
                        >
                        <input
                          v-model="user.email"
                          required
                          type="email"
                          id="form3Example2"
                          class="form-control bg-light"
                        />
                        <small
                          v-if="errors.emailErrorMessage"
                          class="text-danger"
                          >{{ errors.emailErrorMessage }}</small
                        >
                      </div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                          >Numéro de téléphone</label
                        >
                        <input
                          v-model="user.telephone"
                          required
                          type="number"
                          id="form3Example2"
                          class="form-control bg-light text-black"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                          >Date de naissance</label
                        >
                        <input
                          v-model="user.date_naissance"
                          required
                          id="form3Example2"
                          class="form-control bg-light"
                          type="date"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                          >Adresse</label
                        >
                        <input
                          v-model="user.adresse"
                          required
                          type="text"
                          id="form3Example2"
                          class="form-control bg-light"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-2 col-md-2">Sexe</label>
                    <div class="col-sm-10 col-md-5">
                      <div class="form-radio">
                        <div class="radio radiofill radio-primary radio-inline">
                          <label>
                            <input
                              type="radio"
                              required
                              v-model="user.sexe"
                              value="M"
                            />
                            <i class="helper"></i>Masculin
                          </label>
                        </div>
                        <div class="radio radiofill radio-primary radio-inline">
                          <label>
                            <input
                              type="radio"
                              required
                              v-model="user.sexe"
                              value="F"
                            />
                            <i class="helper"></i>Feminin
                          </label>
                        </div>
                      </div>
                      <span class="messages"></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                          >Pays</label
                        >
                        <input
                          class="bg-light form-control"
                          required
                          v-model="user.pays"
                          placeholder="Entrer un pays"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                        >
                          Compétences</label
                        >
                        <input
                          v-model="user.competences"
                          required
                          class="form-control bg-light"
                          placeholder="Entrer vos compétences"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="niv_etude"
                          style="color: #1d4851"
                          >Niveau d'étude</label
                        >
                        <select
                          class="bg-light form-select"
                          required
                          v-model="user.niveau_etude"
                        >
                          <option
                            :value="niveau"
                            v-for="(niveau, index) in niveau_etudes"
                            :key="index"
                          >
                            {{ niveau }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="competence"
                          style="color: #1d4851"
                          >Centres d'intérêt</label
                        >
                        <input
                          v-model="user.centre_interets"
                          class="bg-light form-control"
                          multiple
                        />
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="mb-4">
                      <div class="form-outline">
                        <label
                          class="form-label"
                          for="form3Example2"
                          style="color: #1d4851"
                          >Photo de profil</label
                        >
                        <input
                          @change="($event) => fileChange($event)"
                          type="file"
                          class="form-control"
                          name="photo"
                          accept="image/jpeg, image/png, image/jpg"
                        />
                      </div>
                    </div>
                  </div> -->
                  <button
                    class="btn btn-lg mb-3"
                    style="
                      background-color: #1d4851;
                      color: white;
                      width: 250px;
                      height: 50px;
                    "
                    type="submit"
                  >
                    Sauvegarder
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </DashboardCandidat>
</template>

<script>
import { infoService, accountService, Countries } from "@/services";
import DashboardCandidat from "./DashboardView.vue";
export default {
  name: "ProfilCandidat",
  components: { DashboardCandidat },
  data() {
    return {
      pays: null,
      niveau_etudes: [],
      postes: [],
      user: {
        nom: "",
        prenoms: "",
        adresse: "",
        email: "",
        telephone: 0,
        date_naissance: null,
        centre_interets: null,
        competences: null,
        niveau_etude: null,
        sexe: "",
        photo: null,
      },
      mydata: new FormData(),
      errors: {
        emailErrorMessage: null,
      },
    };
  },
  computed: {
    userConnected() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    get_profil() {
      console.log("kkk");
      accountService
        .get_profil_candidat(this.userConnected.id)
        .then((res) => {
          console.log(res);
          this.user = res.data;
        })
        .catch((err) => console.log(err));
    },
    update_profil() {
      this.mydata.append("nom", this.user.nom);
      this.mydata.append("prenoms", this.user.prenoms);
      this.mydata.append("adresse", this.user.adresse);
      this.mydata.append("telephone", parseInt(this.user.telephone));
      this.mydata.append("pays", this.user.pays ?? "");
      this.mydata.append("date_naissance", this.user.date_naissance ?? "");
      this.mydata.append("niveau_etude", this.user.niveau_etude);
      this.mydata.append("email", this.user.email);

      if (this.user.sexe) {
        this.mydata.append("sexe", this.user.sexe);
      }
      this.mydata.append("competences", JSON.stringify(this.user.competences));
      this.mydata.append(
        "centre_interets",
        JSON.stringify(this.user.centre_interets)
      );
      accountService
        .update_profil_candidat(this.user.id, this.mydata)
        .then((res) => {
          this.mydata = new FormData();
          this.$store.commit("SET_USER", res.data);
          this.$swal.fire({
            icon: "success",
            title: "Profil modifié",
          });
        })
        .catch((err) => {
          if (err.response.status == 400)
            if (err.response.data.email) {
              this.errors.emailErrorMessage = err.response.data.email[0];
            }
          this.$swal.fire({
            icon: "error",
            title: "Une erreur s'est produite",
          });
          console.log(err);
        });
    },
    fileChange(e) {
      const maxSize = 2000000;
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      const file = e.target.files[0];
      if (file.size > maxSize) {
        this.$swal.fire({ title: "fichier trop volumineux", icon: "error" });
      } else if (!allowedFileTypes.includes(file.type)) {
        this.$swal.fire({
          title: "format de fichier incorrect",
          icon: "error",
        });
      } else {
        this.user.photo = e.target.files[0];
        this.mydata.append("photo", e.target.files[0]);
      }
    },
  },
  mounted() {
    this.get_profil();
    this.niveau_etudes = infoService.get_all_niv_etude();
  },
};
</script>
<style>
@import "vue-select/dist/vue-select.css";

.multi_select .vs__selected {
  background-color: #4099ff;
  color: white;
}

.multi_select {
  background-color: rgb(248, 249, 250);
}

.nav-left {
  margin-bottom: 0;
}

.evaluation {
  margin-bottom: -90%;
}

.sidebar {
  margin-top: 50%;
}

section {
  padding: 0px;
}

.form-group {
  margin-bottom: 1.25em;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
</style>
