<template>
    <DashboardCandidat>
        <div class="card">
            <div class="card-body py-5 px-md-5">
                <div class="row d-flex justify-content-center">
                    <div class="row mb-4">
                        <h3 class=" col-md-9 fw-bold evalu">Mes évaluations</h3>

                        <router-link :to="{ name: 'AddEvaluation' }" class=" col-md-3" href=""><button class="btn monbouton ">
                                nouvelle évaluation</button></router-link>

                    </div>
                    <div class="row">
                        <div class="col-6" v-for="(item, index) in evaluations" :key="index">
                            <div class="card comp-card">
                                <div class="card-header d-flex justify-content-between">
                                    <div>
                                        <h3 class="titre">{{ item.nom }}</h3>
                                    </div>
                                    <div class="dropdown">
                                        <button class="btn btn-sm  btnn btn-secondary dropdown-toggle" type="button"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            options
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><button class="dropdown-item" type="button"
                                                    @click="apercu_eval(item.id)">Apercu</button>
                                            </li>
                                            <li><button :data-bs-target="'#exampleModal' + item.id" data-bs-toggle="modal"
                                                    class="dropdown-item" type="button">Assigner à une offre</button>
                                            </li>

                                            <li><button class="dropdown-item text-danger" type="button"
                                                    @click="delete_eval(item.id, index)">Supprimer</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <ul>

                                        <li class="f-w-500">créé le : {{ item.date_creation }}</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-for="(evalu, index) in evaluations" :key="index" class="modal fade" :id="'exampleModal' + evalu.id" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Choisir une offre</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table table-hover m-b-0">
                                <tbody>
                                    <tr v-for="(offre, indice) in offres" :key="indice" class="m-2 offre-choice" @click="assign_offre(offre.id,evalu.id)" data-bs-toggle="modal" data-bs-target="#date_modal" >
                                        <td>Offre pour <b> {{ offre.poste.nom }}</b> créée le {{ offre.date_publication }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                   
                </div>
            </div>
        </div>



<!-- Modal -->
<div class="modal fade" id="date_modal" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel2">Date de fin de l'evaluation</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <input type="datetime-local" v-model="date_fin" class="form_control" placeholder="date de fin">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary mybtn" data-bs-dismiss="modal" @click="validate_assign()">Assigner</button>
      </div>
    </div>
  </div>
</div>
    </DashboardCandidat>
</template>
    
<script>
import DashboardCandidat from '@/views/recruteur/DashboardView.vue'
import { Evaluation } from '@/services/evaluation.service';
import { OffreEmploi } from '@/services/offre_emploi.service';
export default {
    components: { DashboardCandidat },
    data() {
        return {
            evaluations: [],
            date_creations: [],
            offres: [],
            date_fin:null,
            offre_choisi:null,
            eval_choisi:null
        }
    },
    methods: {
        apercu_eval(eval_id) {
            this.$router.push({ name: 'detail_evaluation', params: { id: eval_id } })
        },
        delete_eval(id, index) {
            this.$swal.fire({
                title: 'Êtes-vous sûr(e) ?',
                text: "Vous ne pourrez plus revenir en arrière",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer!',
                cancelButtonText: 'Quitter'
            }).then((result) => {
                if (result.isConfirmed) {
                    Evaluation.delete_evaluation(id)
                        .then((res) => {
                            this.evaluations.splice(index, 1)
                            this.$swal.fire(
                                'Supprimé!',
                                'La suppression a bien été effectué',
                                'success'
                            )
                        })
                        .catch(err => {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'l\'évaluation est assigné à une offre',
                            })
                        })

                }
            })
        },
        assign_offre(offre_id,eval_id) {
           this.offre_choisi = offre_id
           this.eval_choisi = eval_id
        },
        validate_assign(){
            const data = {
                'evaluation_id':this.eval_choisi,
                'offre_id':this.offre_choisi,
                'date_fin':this.date_fin
            }
            Evaluation.assign_evaluation(data).then(
                this.$swal.fire(
                    'Evaluation assignée!',
                    'L\'assignation a bien été effectuée',
                     'success'
                )
            ).catch(err=>{
                console.log(err)
                this.$swal.fire(
                    'Opération échouée!',
                    'L\'assignation a échouée',
                     'error'
                )
            })
        }
    },
    mounted() {
        // Evaluation.my_evaluations().then(res => {
        //     this.evaluations = res.data

        // })
        //     .catch(err => console.log(err))

        // OffreEmploi.get_recruteur_offres_few_detail().then(
        //     res => { this.offres = res.data; console.log(this.offres); }
        // )
    },
};
</script>
<style scoped>
.evalu {
    color: #1D4851;
}

.titre {
    color: #1D4851;
    font-weight: bold;
    font-size: 16px;
}

.monbouton {
    background-color: #cc5500;
    color: white;
    font-weight: bold;
}

.monbouton:hover {
    background-color: #1D4851;
    color: white;
    font-weight: bold;
}

.btnn {
    background-color: #1D4855;
    color: white;
    font-weight: bold;

}

.btnn:hover {
    background-color: #cc5500;
    color: white;
}
.offre-choice{
    cursor: pointer;
}
</style>