<template>
    <DashboardCandidat>
    <section>

        <div class="card mx-4 mx-md-5 shadow-5-strong" style="
background: hsla(0, 0%, 100%, 0.8);
backdrop-filter: blur(30px);
">
            <div class="card-body py-5 px-md-5">
                <div class="row d-flex justify-content-center">
                    <div class="row mb-4 align-items-end">
                        <h5 class="fw-bold  col-md-9" style="color: #1D4851;">Edition experience professionnelle</h5>
                    </div>
                    <form @submit.prevent="confirmForm()">
                        <div class="row">
                            <div class="col-md-12 mb-4">
                                <div class="form-outline">
                                    <label class="form-label" for="nom_entreprise" style="color: #1D4851;">Nom de
                                        l'entreprise</label>
                                    <input v-model="experience.nom_entreprise" type="text" id="nom_entreprise" class="form-control bg-light" required/>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <div class="form-outline">
                                    <label class="form-label" for="form3Example2" style="color: #1D4851;">Date de
                                        debut</label>
                                    <input v-model="experience.date_debut" type="date" id="form3Example2" class="form-control bg-light" required />
                                </div>
                            </div>
                            <div class="col-md-6 mb-4">
                                <div class="form-outline">
                                    <label class="form-label" for="form3Example2" style="color: #1D4851;">Date de
                                        fin</label>
                                    <input v-model="experience.date_fin" type="date" id="form3Example2" class="form-control bg-light" required />

                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <div class="form-outline">
                                    <label class="form-label" for="form3Example2" style="color: #1D4851;">Lieu </label>
                                    <input v-model="experience.lieu" type="text" id="form3Example2" class="form-control bg-light" required />
                                </div>
                            </div>
                            <div class="col-md-6 mb-4">
                                <div class="form-outline">
                                    <label class="form-label" for="form3Example2" style="color: #1D4851;">Poste</label>
                                    <v-select v-model="experience.poste" class=" multi_select bg-light" :options="postes" label="nom" track-by="nom" placeholder="poste occupé"></v-select>
                                </div>
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-sm-10 col-md-12">
                                <textarea v-model="experience.description" rows="5" cols="5" class="form-control bg-light"
                                    placeholder="Description des activités" required></textarea>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-lg mb-3"
                                style="background-color: #1D4851; color: white; width: 25%;"
                                type="submit">mettre a jour</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </section>
</DashboardCandidat>
</template>

<script>
import {infoService} from '@/services'
import { UserExperience } from '@/services/user_experience.service';
import Swal from 'sweetalert2'
import DashboardCandidat from './DashboardView.vue'

export default{
    props:['exp_id'],
    components:{DashboardCandidat},
    data() {
        return {
            postes:[],
            experience:{
                id:null,
                nom_entreprise:'',
                date_debut:null,
                date_fin:null,
                lieu:'',
                description:'',
                poste:null
            }
        }
    },
    methods:{
        confirmForm(){

            const date_debut = new Date(this.experience.date_debut)
            const date_fin = new Date(this.experience.date_fin)
            if(date_debut > date_fin){
                this.errors.push('la date de debut doit etre inférieure a la date de fin')
            }else{
                this.updateExperience()
            }
            
        },
        updateExperience(){
            
            UserExperience.updateExperience(this.experience.id,this.experience)
            .then(res=>{
                Swal.fire({
                        icon: 'success',
                        title: 'Modification éffectuée',
                        text: "l'expérience a été modifié avec succès!",
                        showConfirmButton:false,
                        timer:2000
                    }).then(
                        history.back()
                    )
                })
            .catch(err=>
                Swal.fire({
                        icon: 'error',
                        title: 'Modification échouée',
                        text: "l'Experience n'a pas pu etre modifié",
                        timer:2000
                    }))
        }
    },
    mounted(){
        infoService.get_all_post()
        .then(res=>this.postes=res.data.results)
        .catch(err => console.log(err))

        UserExperience.getExperience(this.exp_id)
        .then(res=>this.experience=res.data)
        .catch(err=>console.log(err))

    }
}
</script>

<style>
.multi_select .vs__selected {
  background-color: #4099ff;
  color: white
}

.multi_select {
  background-color: rgb(248, 249, 250);
}
</style>