<template>
  <div
    id="pcoded"
    class="pcoded iscollapsed"
    theme-layout="vertical"
    vertical-placement="left"
    vertical-layout="wide"
    pcoded-device-type="desktop"
    vertical-nav-type="expanded"
    vertical-effect="shrink"
    vnavigation-view="view1"
    nav-type="st2"
    fream-type="theme1"
    layout-type="light"
  >
    <slot name="navbar">
      <NavbarUser ></NavbarUser>
    </slot>

    <div class="pcoded-main-container" style="margin-top: 80px">
      <div class="pcoded-wrapper">
        <nav
          class="pcoded-navbar d-none d-md-block"
          navbar-theme="theme1"
          active-item-theme="theme1"
          sub-item-theme="theme2"
          active-item-style="style0"
          pcoded-navbar-position="fixed"
        >
          <slot name="sidebarNav"> </slot>
        </nav>
        <div class="pcoded-content ">
          <div class="page-header card" style="border: none">
            <div class="row align-items-end">
              <div class="col-md-8">
                <div class="page-header-title">
                  <!-- <a href="#"><i @click="goBack()" class="feather icon-arrow-left bg-c-blue"></i></a> -->
                  <!-- <div class="d-inline">
                    <h5 class="f-w-700">Tableau de Bord</h5>
                        <span class="f-w-500">Visualiser les statistiques de tout vos recrutements</span>
                    </div> -->
                </div>
              </div>
            </div>

            <div class="row align-items-end">
              <div class="col-md-8">
                <div class="page-header-title"></div>
              </div>
            </div>
          </div>
          <div class="pcoded-inner-content w-md-100">
            <div class="main-body">
              <div class="page-wrapper">
                <div class="page-body">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarUser from "./NavbarUser.vue";
export default {
  components: { NavbarUser },
  methods: {
    goBack() {
      history.back();
    },
    logout() {
      this.$store.commit("LOGOUT");
      this.$router.push({ name: "Home_Page" });
    },
  },
  computed: {},
};
</script>
<style>
.nav-left {
  margin-bottom: 0;
}

.evaluation {
  margin-bottom: -90%;
}

.sidebar {
  margin-top: 50%;
}
@media (max-width: 768px) {
  .pcoded-content {
    margin-left: 0px !important;
  }
}
</style>
