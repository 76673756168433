<template>
    <div>
        <DashboardLayout>
            <template #navbar>
                <NavbarUser></NavbarUser>
            </template>
            <template #sidebarNav>
                
                <SidebarCandidat></SidebarCandidat>
            </template>
            <slot></slot>
        </DashboardLayout>
    </div>
</template>
<script>
import NavbarUser from '@/components/NavbarUser.vue';
import SidebarCandidat from '@/components/SidebarCandidat.vue';
import DashboardLayout from '@/components/DashboardLayout.vue';
export default{
    name:'DashboardCandidat',
    components:{SidebarCandidat,DashboardLayout,NavbarUser}
}
</script>