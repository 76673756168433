<template>
  <DashboardRecruteur>
    <div class="card">
      <div class="card-header">
        <p class="titre" style="color: #cc5500">
          Modification d'offre d'emploi
        </p>
      </div>

      <div class="card-block">
        <form enctype="multipart/form-data" @submit.prevent="confirmForm">
          <div class="card">
            <div class="card-header">
              <p class="titre">Informations relative a la fiche de poste</p>
            </div>
            <div class="card-block">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="Poste"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Poste <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>
                <div class="col-sm-12">
                  <input
                    type="text"
                    v-model="offre_emploi.level1.poste"
                    class="form-control n-input bg-light"
                    placeholder="Veuillez entrer le poste"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level1.poste.$invalid &&
                        v$.offre_emploi.level1.poste.$dirty,
                    }"
                    @blur="v$.offre_emploi.level1.poste.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level1.poste.$invalid &&
                      v$.offre_emploi.level1.poste.$dirty
                    "
                  >
                    <small
                      v-if="v$.offre_emploi.level1.poste.required.$invalid"
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="Email"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Email <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>

                <div class="col-sm-12">
                  <input
                    type="text"
                    v-model="offre_emploi.level1.email"
                    class="form-control n-input bg-light"
                    placeholder="Veuillez entrer l'email à contacter"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level1.email.$invalid &&
                        v$.offre_emploi.level1.email.$dirty,
                    }"
                    @blur="v$.offre_emploi.level1.email.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level1.email.$invalid &&
                      v$.offre_emploi.level1.email.$dirty
                    "
                  >
                    <small
                      v-if="v$.offre_emploi.level1.email.required.$invalid"
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="Telephone"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Numero de Téléphone
                    <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>

                <div class="col-sm-12">
                  <input
                    type="text"
                    v-model="offre_emploi.level1.numero"
                    class="form-control n-input bg-light"
                    placeholder="Veuillez entrer l'email à contacter"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level1.numero.$invalid &&
                        v$.offre_emploi.level1.numero.$dirty,
                    }"
                    @blur="v$.offre_emploi.level1.numero.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level1.numero.$invalid &&
                      v$.offre_emploi.level1.numero.$dirty
                    "
                  >
                    <small
                      v-if="v$.offre_emploi.level1.numero.required.$invalid"
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="Telephone"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Objet du Mail
                    <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>

                <div class="col-sm-12">
                  <input
                    type="text"
                    v-model="offre_emploi.level1.object"
                    class="form-control n-input bg-light"
                    placeholder="Veuillez entrer un objet "
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="description"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Description du poste</label
                  >
                </div>
                <div class="col-sm-12">
                  <ckeditor
                    :editor="editor"
                    v-model="offre_emploi.level1.description"
                    :config="editorConfig"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level1.description.$invalid &&
                        v$.offre_emploi.level1.description.$dirty,
                    }"
                    @blur="v$.offre_emploi.level1.description.$touch"
                  ></ckeditor>

                  <template
                    v-if="
                      v$.offre_emploi.level1.description.$invalid &&
                      v$.offre_emploi.level1.description.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level1.description.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="competence"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >competences requises
                    <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level1.competences"
                    class="form-control bg-light"
                    placeholder="Veuillez entrer les compétences"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level1.competences.$invalid &&
                        v$.offre_emploi.level1.competences.$dirty,
                    }"
                    @blur="v$.offre_emploi.level1.competences.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level1.competences.$invalid &&
                      v$.offre_emploi.level1.competences.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level1.competences.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="niveau d'etude"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Niveau d'etude
                    <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>
                <div class="col-sm-12">
                  <select
                    v-model="offre_emploi.level1.niveau_etude"
                    class="bg-light form-select"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level1.niveau_etude.$invalid &&
                        v$.offre_emploi.level1.niveau_etude.$dirty,
                    }"
                    @blur="v$.offre_emploi.level1.niveau_etude.$touch"
                  >
                    <option
                      :value="niveau"
                      v-for="(niveau, index) in niveau_etudes"
                      :key="index"
                    >
                      {{ niveau }}
                    </option>
                  </select>
                  <template
                    v-if="
                      v$.offre_emploi.level1.niveau_etude.$invalid &&
                      v$.offre_emploi.level1.niveau_etude.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level1.niveau_etude.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <p class="titre">Informations Supplémentaires</p>
            </div>
            <div class="card-block">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="nom_entreprise"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Nom de l'entreprise qui recrute</label
                  >
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level2.nom_entreprise"
                    id="nom_entreprise"
                    name="nom_entreprise"
                    type="text"
                    class="form-control n-input bg-light"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level2.nom_entreprise.$invalid &&
                        v$.offre_emploi.level2.nom_entreprise.$dirty,
                    }"
                    @blur="v$.offre_emploi.level2.nom_entreprise.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level2.nom_entreprise.$invalid &&
                      v$.offre_emploi.level2.nom_entreprise.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level2.nom_entreprise.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="nombre_demande"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Nombre de candidat à embaucher à la fin du recrutement
                    <span style="color: rgb(233, 105, 1)">*</span></label
                  >
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level2.nombre_demande"
                    id="nombre_demande"
                    type="number"
                    class="form-control bg-light"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level2.nombre_demande.$invalid &&
                        v$.offre_emploi.level2.nombre_demande.$dirty,
                    }"
                    @blur="v$.offre_emploi.level2.nombre_demande.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level2.nombre_demande.$invalid &&
                      v$.offre_emploi.level2.nombre_demande.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level2.nombre_demande.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="salaire"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Salaire</label
                  >
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level2.salaire"
                    type="number"
                    class="form-control bg-light"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="date_limite"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Date limite pour cloturer l'offre
                    <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level2.date_limite"
                    id="date_limite"
                    type="datetime-local"
                    @input="handleDateChange"
                    class="form-control n-input bg-light"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level2.date_limite.$invalid &&
                        v$.offre_emploi.level2.date_limite.$dirty,
                    }"
                    @blur="v$.offre_emploi.level2.date_limite.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level2.date_limite.$invalid &&
                      v$.offre_emploi.level2.date_limite.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level2.date_limite.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="type_contrat"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Type de contrat</label
                  >
                </div>
                <div class="col-sm-12">
                  <v-select
                    v-model="offre_emploi.level2.type_contrat"
                    class="bg-light"
                    :options="types_contrat"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level2.type_contrat.$invalid &&
                        v$.offre_emploi.level2.type_contrat.$dirty,
                    }"
                    @blur="v$.offre_emploi.level2.type_contrat.$touch"
                  ></v-select>
                  <template
                    v-if="
                      v$.offre_emploi.level2.type_contrat.$invalid &&
                      v$.offre_emploi.level2.type_contrat.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level2.type_contrat.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="niveau d'etude"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Centre d'intérets
                    <span style="color: rgb(233, 105, 1)">*</span>
                  </label>
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level2.centre_interet"
                    class="bg-light form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <p class="titre">Autres informations</p>
            </div>

            <div class="card-block">
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="localisation"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Localisation</label
                  >
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level3.localisation"
                    id="localisation"
                    name="localisation"
                    type="text"
                    class="form-control n-input bg-light"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level3.localisation.$invalid &&
                        v$.offre_emploi.level3.localisation.$dirty,
                    }"
                    @blur="v$.offre_emploi.level3.localisation.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level3.localisation.$invalid &&
                      v$.offre_emploi.level3.localisation.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level3.localisation.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for="Missions_poste"
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Age requis</label
                  >
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level3.age_requis"
                    type="number"
                    class="form-control bg-light"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12">
                  <label
                    for=""
                    class="block"
                    style="color: #1d4851; font-weight: bold"
                    >Langue requise</label
                  >
                </div>
                <div class="col-sm-12">
                  <input
                    v-model="offre_emploi.level3.langue_requise"
                    type="text"
                    class="form-control bg-light"
                    :class="{
                      'is-invalid':
                        v$.offre_emploi.level3.langue_requise.$invalid &&
                        v$.offre_emploi.level3.langue_requise.$dirty,
                    }"
                    @blur="v$.offre_emploi.level3.langue_requise.$touch"
                  />
                  <template
                    v-if="
                      v$.offre_emploi.level3.langue_requise.$invalid &&
                      v$.offre_emploi.level3.langue_requise.$dirty
                    "
                  >
                    <small
                      v-if="
                        v$.offre_emploi.level3.langue_requise.required.$invalid
                      "
                      class="invalid-feedback d-block"
                    >
                      Ce champs est requis
                    </small>
                  </template>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-10">
                  <label
                    class="form-label"
                    for="image"
                    style="color: #1d4851; font-weight: bold"
                    >Image qui accompagnera votre offre
                  </label>
                  <input
                    id="file-input"
                    @change="($e) => fileChanged($e)"
                    type="file"
                    class="form-control"
                    name="image"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-10">
                  <label
                    class="form-label"
                    for="image"
                    style="color: #1d4851; font-weight: bold"
                    >Réception des documents hors plateforme :
                  </label>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      v-model="offre_emploi.is_Email"
                    />
                    <label
                      class="form-check-label"
                      for="flexCheckIndeterminate"
                    >
                      Par email
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="offre_emploi.is_Whatsapp"
                    />
                    <label
                      class="form-check-label"
                      for="flexCheckIndeterminate"
                    >
                      Par Whatsap
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn btn-primary float-end" type="submit">
            <div
              class="spinner-grow text-light"
              role="status"
              v-if="loading"
            ></div>
            Sauvegarder
          </button>
        </form>
      </div>
    </div>
  </DashboardRecruteur>
</template>
<script>
import DashboardRecruteur from "../DashboardView.vue";
import { OffreEmploi } from "@/services/offre_emploi.service";
import { infoService, accountService } from "@/services";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import validationHelpers from "@/utils/validation";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: { DashboardRecruteur },
  props: ["id"],
  mounted() {
    this.get_offre();
    this.recruteur = accountService.USER;
    this.niveau_etudes = infoService.get_all_niv_etude();
  },
  data() {
    return {
      loading: false,
      v$: useValidate(),
      editor: ClassicEditor,
      editorData: "<p>Veuillez mettre la description ici</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      recruteur: null,
      postes: [],
      competences: [],
      types_contrat: ["CDD", "CDI", "FREELANCE", "STAGE"],
      niveau_etudes: [],
      centre_interets: [],
      offre_emploi: {
        is_Whatsapp: false,
        is_Email: false,
        level1: {
          poste: null,
          email: null,
          description: "",
          competences: null,
          niveau_etude: null,
          numero: null,
          object: null,
        },
        level2: {
          nom_entreprise: "",
          nombre_demande: 1,
          salaire: 0,
          date_limite: null,
          type_contrat: "",
          centre_interet: null,
        },
        level3: {
          localisation: "",
          age_requis: 0,
          langue_requise: "",
          image: null,
        },
      },
      mydata: new FormData(),
    };
  },
  validations() {
    return {
      offre_emploi: {
        level1: {
          email: { required, email },
          poste: { required },
          competences: { required },
          niveau_etude: { required },
          description: { required },
          numero: { required },
        },
        level2: {
          nom_entreprise: { required },
          nombre_demande: { required },
          date_limite: { required },
          type_contrat: { required },
        },
        level3: {
          localisation: { required },
          langue_requise: { required },
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    ...validationHelpers,
    confirmForm(val) {
      this.loading = true;
      console.log(this.offre_emploi.level3.age_requis);
      if (this.v$.offre_emploi.$invalid) {
        this.forceValidation();
        this.$swal.fire(
          "Opération echoué",
          "Vous n'avez pas remplir tout les champs, veuillez vérifier le formulaire"
        );
        return;
      }
      this.mydata.append("poste", this.offre_emploi.level1.poste);
      this.mydata.append(
        "telephone_destination",
        this.offre_emploi.level1.numero
      );

      this.mydata.append("description", this.offre_emploi.level1.description);
      this.mydata.append("competences", this.offre_emploi.level1.competences);
      this.mydata.append("niveau_etude", this.offre_emploi.level1.niveau_etude);
      this.mydata.append("recruteur", this.user.id);
      this.mydata.append("objet_email", this.offre_emploi.level1.object);

      this.mydata.append(
        "nom_entreprise",
        this.offre_emploi.level2.nom_entreprise
      );
      this.mydata.append(
        "nombre_demande",
        this.offre_emploi.level2.nombre_demande
      );
      this.mydata.append("date_limite", this.offre_emploi.level2.date_limite);
      this.mydata.append("type_contrat", this.offre_emploi.level2.type_contrat);
      this.mydata.append("email_destination", this.offre_emploi.level1.email);

      this.mydata.append(
        "centre_interet",
        this.offre_emploi.level2.centre_interet
      );
      this.mydata.append("localisation", this.offre_emploi.level3.localisation);
      if (!this.offre_emploi.level3.age_requis) {
        this.mydata.append("age_requis", 0);
      } else {
        this.mydata.append("age_requis", this.offre_emploi.level3.age_requis);
      }
      if (!this.offre_emploi.level2.salaire) {
        this.mydata.append("salaire", 0);
      } else {
        this.mydata.append("salaire", this.offre_emploi.level2.salaire);
      }
      this.mydata.append(
        "langue_requise",
        this.offre_emploi.level3.langue_requise
      );
      this.update_offre(this.mydata);
    },
    handleDateChange(event) {
      const selectedDate = new Date(event.target.value);
      const today = new Date();

      if (selectedDate < today) {
        // Empêcher la mise à jour de la valeur si la date sélectionnée est antérieure à la date d'aujourd'hui
        event.target.value = "";
      }
    },
    async update_offre(data) {
      await OffreEmploi.update_offre(data, this.id)
        .then((res) => {
          this.loading = false;

          this.$swal.fire(
            "Modification effectuée !",
            "l'offre a bien été modifiée",
            "success"
          );
          this.$router.push({ name: "dashboard_recruteur" });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$swal.fire(
            "Opération echoué",
            "Une erreur est survenue lors de la modification",
            "error"
          );
        });
    },
    formatData(data) {
      this.offre_emploi.level1.poste = data.poste;
      this.offre_emploi.level1.description = data.description;
      this.offre_emploi.level1.competences = data.competences;
      this.offre_emploi.level1.niveau_etude = data.niveau_etude;
      this.offre_emploi.level2.nom_entreprise = data.nom_entreprise;
      this.offre_emploi.level2.nombre_demande = data.nombre_demande;
      this.offre_emploi.level2.salaire = data.salaire;
      this.offre_emploi.level2.date_limite = data.date_limite;
      this.offre_emploi.level2.type_contrat = data.type_contrat;
      this.offre_emploi.level2.centre_interet = data.centre_interet;
      this.offre_emploi.level3.localisation = data.localisation;
      this.offre_emploi.level3.age_requis = data.age_requis;
      this.offre_emploi.level3.langue_requise = data.langue_requise;
      this.offre_emploi.level3.image = data.image;
      this.offre_emploi.level1.email = data.email_destination;
      this.offre_emploi.level1.numero = data.telephone_destination;
      this.offre_emploi.is_Email = data.is_Email;
      this.offre_emploi.is_Whatsapp = data.is_Whatsapp;
    },
    async get_offre() {
      await OffreEmploi.get_offre(this.$route.params.id)
        .then((res) => this.formatData(res.data))
        .catch((err) => console.log(err));
    },
    fileChanged(e) {
      document
        .getElementById("file-input")
        .addEventListener("change", function () {
          this.value = ""; // Vider le champ de fichier après la sélection d'un fichier
        });
      if (e.target.files[0].size / (1024 * 1024) > 10) {
        Swal.fire({
          icon: "error",
          title: "Image non téléchargée",
          text: " Veuillez télécharger une image inférieurá 10 MB s'il vous plaît",
          timer: 3000,
        });
        document.getElementById("file-input").value = "";
        return;
      }
      this.offre_emploi.level3.image = e.target.files[0];
      this.mydata.append("image", this.offre_emploi.level3.image);
    },
  },
};
</script>

<style></style>
