import { createRouter, createWebHistory } from 'vue-router'
import { authGuard,accessAuthPage } from '@/services/auth-guard'
import Signup_Page from '@/views/Signup_Page'
import Home_Page from '@/views/Home_Page'
import Signup_Page_Recruiteur from '@/views/Signup_Page_Recruiteur'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import NewPassword from '@/views/auth/NewPassword.vue'
import choice_role from '@/views/choice_role'
import ProfilRecruteur from '@/views/recruteur/ProfilRecruteur.vue'
import ProfilCandidat from '@/views/candidat/ProfilCandidat'
import ExperiencesView from '@/views/candidat/ExperiencesView.vue'
import AddExperienceView from '@/views/candidat/AddExperienceView.vue'
import EditExperienceView from '@/views/candidat/EditExperienceView.vue'
import StatistiqueView from '@/views/recruteur/StatistiqueView.vue'
import CreateOffreView from '@/views/recruteur/offre_emploi/CreateOffreView.vue'
import OffreDisponible from '@/views/recruteur/offre_emploi/OffreDisponible.vue'
import DetailOffre from '@/views/recruteur/offre_emploi/DetailOffre.vue'
import LoginView from '@/views/auth/LoginView.vue'
import EditOffreView from '@/views/recruteur/offre_emploi/EditOffreView.vue'
import Candidature from '@/views/candidat/Candidature.vue'
import Dashboard from '@/views/candidat/Dashboard.vue'
import DocumentCandidat from '@/views/candidat/DocumentCandidat.vue'
import MotivationCandidat from "@/views/candidat/MotivationCandidat.vue";
import ManageOffreView from '@/views/recruteur/offre_emploi/ManageOffreView.vue'
import InfoCandidat from '@/views/candidat/InfoCandidat.vue'
import PaymentView from '@/views/PaymentView'
import EntretienView from '@/views/recruteur/entretien/EntretienView.vue'
import EvaluationView from '@/views/recruteur/evaluation/EvaluationView.vue'
import AddEvaluationView from '@/views/recruteur/evaluation/AddEvaluationView.vue'
import DetailEvaluationView from '@/views/recruteur/evaluation/DetailEvaluationView.vue'
import DetailCandidatureView from '@/views/candidat/DetailCandidatureView.vue'
import PassEvaluationView from '@/views/candidat/PassEvaluationView.vue'
import ListContesationView from '@/views/recruteur/offre_emploi/ListContestationView.vue'
import store from '@/store'
const routes = [
  {
    path: "/",
    name: "Home_Page",
    component: Home_Page,
  },
  {
    path: "/candidat/register",
    name: "Signup_Page",
    component: Signup_Page,
    // beforeEnter: (to, from, next) => { if(store.getters.getUser) return next('/login')}
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    // beforeEnter: (to, from, next) => { if(store.getters.getUser) return next('/login')}
  },
  {
    path: "/Recruteur/register",
    name: "register_recruiteur",
    component: Signup_Page_Recruiteur,
    // beforeEnter: (to, from, next) => { if(store.getters.getUser) return next('/login')}
  },
  {
    path: "/utilisateur",
    name: "role",
    component: choice_role,
    // beforeEnter: (to, from, next) => { if(store.getters.getUser) return next('/login')}
  },
  {
    path: "/dashboard-recruteur",
    name: "dashboard_recruteur",
    component: StatistiqueView,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/dashboard-recruteur/profil",
    name: "profil_recruteur",
    component: ProfilRecruteur,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/dashboard-recruteur/add_offre",
    name: "add_offre",
    component: CreateOffreView,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/dashboard-recruteur/edit_offre/:id",
    name: "edit_offre",
    component: EditOffreView,
    props: true,
    meta: { requiresAuth: true, type: "recruteur" },
  },

  {
    path: "/dashboard-recruteur/manage_offre/:id",
    name: "manage_offre",
    component: ManageOffreView,
    props: true,
    meta: { requiresAuth: true, type: "recruteur" },
  },

  {
    path: "/dashboard-candidat/profil",
    name: "profil_candidat",
    component: ProfilCandidat,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/dashboard-candidat/experiences",
    name: "experiences",
    component: ExperiencesView,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/dashboard-candidat/add_experience",
    name: "add_experience",
    component: AddExperienceView,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/dashboard-candidat/edit_experience/:exp_id",
    name: "edit_experience",
    component: EditExperienceView,
    props: true,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: ForgotPassword,
  },
  {
    path: "/detailOffre/:id",
    name: "detail_offre",
    component: DetailOffre,
    props: true,
  },
  {
    path: "/offre_disponible",
    name: "OffreDisponible",
    component: OffreDisponible,
  },

  {
    path: "/password-reset-confirm/:user_id/:token",
    name: "reset_password",
    component: NewPassword,
    props: true,
  },
  {
    path: "/mes_candidatures",
    name: "Candidature",
    component: Candidature,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/dashboard-candidat",
    name: "dashboard_candidat",
    component: Dashboard,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/DocumentCandidat",
    name: "Document",
    component: DocumentCandidat,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/lettre_motivation",
    name: "MotivationCandidat",
    component: MotivationCandidat,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/InfoCandidat/:id",
    name: "InfoCandidat",
    component: InfoCandidat,
  },
  {
    path: "/paiement",
    name: "Paiement",
    component: PaymentView,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  {
    path: "/Recruteur/Entretien",
    name: "EntretienView",
    component: EntretienView,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/Recruteur/Evaluation",
    name: "EvaluationView",
    component: EvaluationView,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/Recruteur/Evaluation/ajouter",
    name: "AddEvaluation",
    component: AddEvaluationView,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/Recruteur/Evaluation/:id",
    name: "detail_evaluation",
    component: DetailEvaluationView,
    props: true,
    meta: { requiresAuth: true, type: "recruteur" },
  },
  {
    path: "/candidat/details_candidature/:id",
    name: "details_candidature",
    component: DetailCandidatureView,
    props: true,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/candidat/evaluation/:id",
    name: "pass_evaluation",
    component: PassEvaluationView,
    props: true,
    meta: { requiresAuth: true, type: "candidat" },
  },
  {
    path: "/Recruteur/contestion/:id",
    name: "list_contestation",
    component: ListContesationView,
    props: true,
    meta: { requiresAuth: true, type: "recruteur" },
  },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to,from,next)=>{
        if(to.meta.requiresAuth && to.meta.type == 'candidat'){
            const token  = store.getters.getToken
            const user_role = store.getters.getRole
            if(token){
                if(user_role == 'candidat'){
                    next()
                }else{
                    router.push({name:`dashboard_${user_role}`})
                }
            }else{
                router.push({name:'login'})
            }
        }else if(to.meta.requiresAuth && to.meta.type == 'recruteur'){
            const token  = store.getters.getToken
            const user_role = store.getters.getRole
            if(token){
                if(user_role == 'recruteur'){
                    next()
                }else{
                    router.push({name:`dashboard_${user_role}`})
                }
            }else{
                router.push({name:'login'})
            }
        }
        next()
})

export default router