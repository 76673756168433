<template>
  <GuestLayout>
    <section class="container" style="padding-top: 100px;">
      <div class="card" style="border-radius: 1rem;">
        <div class="row g-0">
          <div class="col-md-6 col-lg-7 d-none d-md-block img_illustration" style="border-radius: 1rem 0 0 1rem;">
            <img src="@/assets/images/illustration_pg_connexion.png" alt="login form" class="illustration"
              style="border-radius: 1rem 0 0 1rem;" />
          </div>
          <div class="col-md-6 col-lg-5 d-flex align-items-center">
            <div class="card-body p-4 p-lg-5 text-black">

              <form @submit.prevent="confirmForm()">
                <div class="d-flex align-items-center mb-3 pb-1">
                  <img class="logo" src="@/assets/images/logo.png" alt="image du logo de l'entreprise">
                </div>

                <h5 class="fw-normal mb-3 pb-3 text-center" style="letter-spacing: 1px;"><span
                    class="phrase_connexion">Reinitialisaion de mot de passe</span></h5>
                <div v-if="have_errors">
                  <div v-for="error in errors" class="alert alert-danger small"> {{ error }}</div>
                </div>
                <input type="hidden" name="token" :value="token">
                <input type="hidden" name="id" :value="user_id">
                <div class="form-floating mb-3">
                  <input v-model="user.password" type="password" class="form-control" id="floatingPassword"
                    placeholder="Password" required>
                  <label for="floatingPassword">Nouveau mot de passe</label>
                </div>
                <div class="form-floating mb-3">
                  <input v-model="confpassword" type="password" class="form-control" id="floatingPassword2"
                    placeholder=" confirm Password" required>
                  <label for="floatingPassword2">Confirmer votre mot de passe</label>
                </div>
                <div class="d-grid gap-2 col-12 mx-auto mb-3">
                  <button class="btn btn-lg mb-3" type="submit"><span style="color: white;">Reinitialiser</span></button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </GuestLayout>
</template>
  
<script>

import GuestLayout from '@/components/GuestLayout.vue'
import { accountService } from '@/services'
export default {
  components: { GuestLayout },
  props: ['token', 'user_id'],
  data() {
    return {
      user: {
        password: ''
      },
      confpassword: '',
      errors: [],
    }
  },
  computed: {
    have_errors() {
      return this.errors.length != 0 ? true : false
    }
  },
  methods: {
    confirmForm() {
      this.errors = []
      if (this.user.password != this.confpassword) {
        this.errors.push('vous avez mal confirmé votre mot de passe')
      }
      if (this.user.email == '') {
        this.errors.push('l\'adresse email est obligatoire')
      }
      if (this.user.password.length < 8) {
        this.errors.push('le mot de passe doit comporter au moins 8 caractère')
      }
      if (this.user.password == '' || this.confpassword == '') {
        this.errors.push('le mot de passe est obligatoire')
      }
      if (this.errors.length == 0) {
        this.reset_password()
      }
    },
    reset_password() {
      let payload = { 'token': this.token, 'user_id': this.user_id, 'password': this.user.password }
      accountService.reset_password(payload).
        then(res => {
          this.$router.push({ name: 'Home_Page' })
        }).
        catch(err => {
          console.log(err)
        })

    }

  }
}
</script>
  
<style scoped>
.logo {
  width: 30%;
  height: 30%;
  margin-top: -5%;
  margin-left: 25%;
  border-radius: 100%;
  border: 1px;
  box-shadow: 3px 3px 3px 3px rgb(159, 155, 155);
  ;
}

.illustration {
  width: 105%;
  height: auto;
}

.img_illustration {
  background-color: #bfe5ed;

  height: 10%;
}

.phrase_connexion {
  font-weight: bold;
  margin-left: 15%;
  font-size: 125%;
  color: #1D4851;
}

.btn:hover {
  background-color: #cc5500;
}

.btn {
  background-color: #1D4851;
}

.logo_google {
  margin-right: 10%;
}

#btn_google {
  background-color: #FF5733;
  color: white;
}

#btn_google:hover {
  background-color: #fefdfd;
  border: solid 2px #FF5733;
  color: black;
}

.R-lien_recruteur {
  color: #1D4851;
}

.R-lien_recruteur:hover {
  color: #cc5500;
}

.R-espaceRecruteur {
  margin-top: -15%;
}

</style>
  

