<template>
  <GuestLayout>
    <template #navbar>
      <div v-if="this.$store.getters.getUser != null">
        <NavbarUser :Sidebar="false"></NavbarUser>
      </div>
    </template>
    <section
      :id="section_id"
      class="d-flex align-items-center"
      style="padding-top: 30px"
      :style="offre_emplois.length == 0 ? 'height:80vh' : ''"
    >
      <div
        v-if="offre_emplois.length !== 0"
        class="container d-flex flex-column align-items-center justify-content-center"
        data-aos="fade-up"
      >
        <div class="row w-100 justify-content-center">
          <span class="col-md-5">
            <input
              type="text"
              v-model="search"
              class="form-control"
              id="floatingInput"
              @input="searchMessage"
              placeholder="Rechercher une offre d'emploi"
              style="width: 100% !important; height: 50px"
            />
          </span>
        </div>
        <section class="container-fluid m-4 col-md-10">
          <CardOffre
            v-for="(item, index) in searchResponse ? searchJob : offre_emplois"
            :key="index"
            :offre="item"
          >
          </CardOffre>
        </section>
      </div>
      <div v-else class="text-center d-flex justify-content-center w-100">
        Aucune offre d'emploi disponible
      </div>
    </section>
  </GuestLayout>
</template>
<script>
import "@/assets/js/main.js";
import CardOffre from "@/components/CardOffre.vue";
import GuestLayout from "@/components/GuestLayout.vue";
import NavbarUser from "@/components/NavbarUser.vue";
import { OffreEmploi } from "@/services/offre_emploi.service";
import moment from "moment";
import DashboardRecruteur from "../DashboardView.vue";

export default {
  components: { GuestLayout, NavbarUser, CardOffre, DashboardRecruteur },
  data() {
    return {
      offre_emplois: [],
      search: null,
      searchResponse: false,
      searchJob: [],
    };
  },
  computed: {
    section_id() {
      if (this.$store.getters.getUser == null) {
        return "hero-rbk";
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.getAllOffre();
  },
  methods: {
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getAllOffre() {
      await OffreEmploi.get_all_offres(true, false)
        .then((res) => {
          console.log(res);
          const data = res.data.results;

          for (let i = 0; i <= data.length; i++) {
            if (new Date(data[i]?.date_limite) > new Date()) {
              this.offre_emplois.push(data[i]);
            }
          }
          // this.offre_emplois = res.data.results;
        })
        .catch((err) => console.log(err));
    },
    async searchMessage(event) {
      if (this.search) {
        this.searchResponse = false;
        this.searchJob = [...this.offre_emplois].filter((element) => {
          return (
            element.poste
              ?.toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            this.getDate(element.date_publication)
              ?.toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            element.nom_entreprise
              ?.toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            element.niveau_etude
              ?.toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            element.localisation
              ?.toLowerCase()
              .includes(event.target.value.toLowerCase())
          );
        });

        this.searchResponse = true;
      } else {
        this.getAllOffre();
        this.searchResponse = false;
      }
    },
    // showOffre(offre_id) {
    //   this.$router.push({ name: 'detail_offre', params: { 'id': offre_id } })
    // }
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}

#R_btn_rechercher {
  background-color: #1d4851;
  color: white;
  width: 150px;
  height: 50px;
}

#R_btn_rechercher:hover {
  background-color: #cc5500;
}

#R_btn_connexion {
  background-color: #1d4851;
  color: white;
}

#R_btn_connexion:hover {
  background-color: #cc5500;
}

#Footer1 {
  margin-left: 80%;
  margin-top: -30%;
}

#R-footer {
  margin-left: 20%;
}

.card {
  margin-top: 5%;
}

.logo {
  width: 20%;
  height: 20%;
  margin-top: 10%;
  margin-left: 5%;
  border-radius: 100%;
  border: 1px;
}

.metier {
  color: #1d4851;
  font-weight: bold;
  font-size: 20px;
}

.socie {
  font-weight: bold;
  font-size: 15px;
}

.offre {
  font-size: 15px;
  font-weight: bold;
  color: #1c5e74;
  margin-left: 70%;
  cursor: pointer;
}
</style>
