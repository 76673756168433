<template>
<GuestLayout>
  <template #navbar>
    <div v-if="this.$store.getters.getUser==null">
      <NavbarGuest></NavbarGuest>
    </div>
    <div v-else>
      <NavbarUser :Sidebar="false"></NavbarUser>
    </div>
  </template>
  <section id="hero-rbk" class="d-flex align-items-center" style="padding-top:60px;">

    <div class="container d-flex flex-column align-items-center justify-content-center" data-aos="fade-up">
      <h1 class="mb-4">Rechercher et postuler pour votre futur emploi</h1>
        <!-- <div class="row">
            <span class="col-5"><input type="text" class="form-control" id="floatingInput" placeholder="Rechercher une offre d'emploi" style="width: 300px; height: 50px"></span>
        <span class="col-5">
          <select class="form-select" aria-label="Default select example" style="width: 300px; height: 50px;">
            <option selected> Sélectionnez votre localité</option>
            <option value="1">Cotonou</option>
            <option value="2">Port-Novo</option>
            <option value="3">Ouidah</option> 
        </select>
      </span>
      <span class="col-2"><a href=""><button class="btn" type="button" id="R_btn_rechercher" >Rechercher</button></a></span>
    </div> -->
        <h2>Si vous êtes un recruteur, publiez vos annonces</h2>
      <img src="@/assets/images/illustration_recruitement.png" class="img-fluid hero-img" alt="" data-aos="zoom-in" data-aos-delay="150">
    </div>

  </section>
  <section id="about" class="about-rbk">
      <div class="container">
        <div class="row no-gutters">
          <div class="content col-5 d-flex align-items-stretch" data-aos="fade-right">
            <div class="content">
              <h3>Kapital Humain & Conseils</h3>
              <p>Gbégamey-BENIN</p>
              <p>Imeuble BOURJON à trois étages situé dans la von en face de la Soneb</p>
              <p><b>Téléphone: </b>+(229)99915050/+(229)65 66 04 38</p>
              <p><b>Email: </b>gnankpan@kapitalhc.com</p>
            </div>
          </div>
          <div class="col-xl-7 d-flex align-items-stretch" data-aos="fade-left">
            <div class="icon-boxes d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-md-6 icon-box" data-aos="fade-up" id="R-footer" data-aos-delay="100">
                  <i class="bx bx-receipt"></i>
                  <h4>Liens importants</h4>
                  <li>Offres D'emploi</li>
                  <li>Espace Recruteur</li>
                </div>
                <div class="col-md-6 icon-box" id="Footer1" data-aos="fade-up" data-aos-delay="200">
                  <i class="bx bx-cube-alt"></i>
                  <h4>Kapital Humain & Conseils</h4>
                  <p>Rendre l'avenir plus radieux</p>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  </GuestLayout>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import NavbarUser from '@/components/NavbarUser.vue';
import NavbarGuest from '@/components/NavbarGuest.vue'
import GuestLayout from '@/components/GuestLayout.vue'
export default{
  components:{GuestLayout,NavbarGuest,NavbarUser}
}

</script>
<style>
.icon-box:before {
    content: "";
}

.text_recruit{
    color: #1D4851;
    font-size: 185%;
}

.R-img_iconeReccherche{
  width: 5%;
  height: 20%;
}
#R_btn_rechercher{
 background-color: #cc5500; 
  color: white; 
  width: 150px; 
  height: 50px;
}
#R_btn_rechercher:hover{
  background-color: #1D4851;
}

#Footer1{
  margin-left: 80%;
  margin-top: -30%;
}
#R-footer{
  margin-left: 20%;
}


</style>