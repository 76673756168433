<template>
  <DashboardRecruteur>
    <div class="card">
      <div class="card-header">
        <h3>{{ offre_emploi?.poste }}</h3>
        <div class="card-header-right">
          <div class="btn-group">
            <!-- <button
              class="btn mybtn rounded p-1 dropdown-item"
              @click="showOffre()"
            >
              voir l'offre
            </button> -->

            <button
              class="btn mybtn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Actions
            </button>
            <ul class="dropdown-menu">
              <!-- <li>
                <a
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                  >Définir les critères</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  >Rouvrir l'offre</a
                >
              </li> -->
              <li>
                <a class="dropdown-item" @click="showOffre()">voir l'offre </a>
              </li>
              <!-- <li>
                <a class="dropdown-item" href="#" @click="pushOffre()"
                  >Publier l'offre
                </a>
              </li> -->
              <!-- <li><a class="dropdown-item" href="#">Lancer le tri</a></li> -->
              <!-- <li>
                <router-link
                  :to="{
                    name: 'list_contestation',
                    params: { id: offre_emploi?.id },
                  }"
                  class="dropdown-item"
                  >les contestations</router-link
                >
              </li> -->
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="closeOffre()"
                  style="color: #f53838"
                  >Fermer l'offre</a
                >
              </li>
            </ul>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">
                    Date limite
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="form-floating mb-3">
                    <input
                      type="date"
                      v-model="date_limite"
                      class="form-control"
                      id="msgcont1"
                      name=""
                      required
                    />
                    <label for="msgcont1">Date limite de l'offre</label>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    quitter
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="rouvrir"
                  >
                    rouvrir l'offre
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade"
            id="exampleModal3"
            tabindex="-1"
            aria-labelledby="exampleModalLabel3"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <form action="" @submit.prevent="definir_critere">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel3">
                      points pour chaque compétence
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="form-floating mb-3">
                      <!-- <input
                        type="number"
                        min="1"
                        max="50"
                        class="form-control"
                        :id="'msgcont' + index"
                        name=""
                        required
                      /> -->
                      <!-- <label :for="'msgcont' + index">item.nom</label> -->
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      définir
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row card-block">
        <div class="col-md-12">
          <div>
            <div class="h5">Liste des candidats</div>
            <table
              class="table table-hover"
              v-if="this.candidatures.length !== 0"
            >
              <thead>
                <tr>
                  <th>Nom et Prénoms</th>

                  <th class="d-none d-md-table-cell">Date postulation</th>
                  <th class="d-none d-sm-table-cell">CV</th>
                  <th>Statut</th>
                  <th>Note</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for="(item, index) in candidatures" :key="index" @click="info_candidat(item.candidat.id)"> -->
                <tr v-for="(item, index) in candidatures" :key="index">
                  <td>{{ item.candidat.nom }} {{ item.candidat.prenoms }}</td>
                  <td class="d-none d-md-table-cell">
                    {{ getDate(item.date_postulation) }}
                  </td>
                  <td class="text-start d-none d-sm-table-cell">
                    <ul class="list-unstyled">
                      <li>
                        <a
                          class="text-decoration"
                          :href="item.cv.cv"
                          target="_blank"
                          >Voir</a
                        >
                      </li>
                    </ul>
                  </td>
                  <td>{{ item.statut }}</td>
                  <td>
                    <span>
                      {{ item.note }}
                    </span>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button
                        class="btn btn-sm dropdown-toggle"
                        style="background-color: #1d4851; color: white"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Options
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            @click="info_candidat(item.id)"
                            >infos sur le candidat</a
                          >
                        </li>
                        <!-- <li>
                          <a
                            class="dropdown-item cursor-pointer"
                            @click="noterCandidat('programme')"
                            >programmer un entretien</a
                          >
                        </li> -->
                        <li>
                          <a
                            class="dropdown-item cursor-pointer"
                            @click="
                              noterCandidat({ id: item.id, note: 'note',index:index })
                            "
                            >noter le candidat</a
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item cursor-pointer"
                            href="#"
                            @click="rejeter(item.id, index, 'Embauché(e)')"
                            >embaucher le candidat</a
                          >
                        </li>
                        <li>
                          <hr class="dropdown-divider" />
                        </li>
                        <li v-if="item.statut != 'Rejeté'">
                          <a
                            class="dropdown-item"
                            href="#"
                            style="color: #f53838"
                            @click="rejeter(item.id, index, 'Rejeté(e)')"
                            >rejeter candidature</a
                          >
                        </li>
                        <li v-if="item.statut == 'Rejeté'">
                          <a
                            class="dropdown-item"
                            href="#"
                            style="color: #0b6b97"
                            @click="rejeter(item.id, index, 'En cours')"
                            >Reprendre la candidature</a
                          >
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-center">Aucune candidature</p>
          </div>
          <div
            class="modal fade"
            :class="{ 'd-block': open, show: open }"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Envoie de mes documents
                  </h5> -->
                  <button
                    type="button"
                    class="btn-close"
                    @click="open = false"
                  ></button>
                </div>
                <form @submit.prevent="noted">
                  <div class="modal-body bg-white">
                    <div class="mb-2" v-if="noterCandidat">
                      <div class="form-outline">
                        <label
                          for="note"
                          class="block"
                          style="color: #1d4851; font-weight: bold"
                          >Note
                          <span style="color: rgb(233, 105, 1)">*</span>
                        </label>
                      </div>
                      <div class="mb-2" v-if="noterCandidat">
                        <div class="form-outline">
                          <input class="form-control" v-model="note" required />
                        </div>
                      </div>
                    </div>
                    <div class="mb-2" v-else-if="programme">
                      <div class="form-outline">
                        <label
                          for="date"
                          class="block"
                          style="color: #1d4851; font-weight: bold"
                          >Date d'entretien
                          <span style="color: rgb(233, 105, 1)">*</span>
                        </label>
                      </div>
                      <div class="mb-2">
                        <div class="form-outline">
                          <input type="data" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer justify-content-between">
                    <button
                      type="reset"
                      class="btn ms-3 btn-secondary"
                      @click="open = false"
                    >
                      Fermer
                    </button>
                    <button type="submit" class="btn btn-primary">
                      <div
                        class="spinner-grow text-light"
                        role="status"
                        v-if="loading"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      Valider
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardRecruteur>
</template>
<script>
import DashboardRecruteur from "@/views/recruteur/DashboardView.vue";
import { OffreEmploi } from "@/services/offre_emploi.service";
import { Candidature } from "@/services/candidature.service";
import moment from "moment";

export default {
  components: { DashboardRecruteur },
  // id represente l'id de l'offre
  props: ["id"],
  mounted() {
    OffreEmploi.get_offre(this.$route.params.id)
      .then((res) => {
        this.offre_emploi = res.data;
      })
      .catch((err) => console.log(err));

    Candidature.candidatures(this.$route.params.id)
      .then((res) => {
        // console.log("cans", res);

        this.candidatures = res.data.results;
      })
      .catch((err) => console.log(err));
  },
  data() {
    return {
      index:0,
      loading: false,
      open: false,
      date: null,
      note: null,
      offre_emploi: null,
      candidatures: [],
      date_limite: null,
      criteres: [],
      valueCandidat: null,
      idCandidat: null,
    };
  },
  methods: {
    getDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    info_candidat(candidat_id) {
      this.$router.push({
        name: "InfoCandidat",
        params: { id: candidat_id },
        query: {
          id_emploie: this.$route.params.id,
        },
      });
    },
    rejeter(id, index, value) {
      console.log(value);
      this.$swal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: `Voulez-vous vraiment ${value} cette candidature ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Oui, ${value}`,
          cancelButtonText: "Quitter",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Candidature.status_candidature(id, { statut: value })
              .then((res) => {
                this.candidatures[index].statut = value;
                this.$swal.fire({
                  title: `Candidature ${value}`,
                  icon: "success",
                });
              })
              .catch((err) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Erreur d'opération !",
                });
                console.log(err);
              });
          }
        });
    },
    rouvrir() {
      if (this.offre_emploi.statut == "En cours") {
        this.$swal.fire({
          title:
            "L'offre doit être déjà fermée avant d'éffectuer cette opération",
          icon: "error",
        });
      } else {
        OffreEmploi.rouvrir_offre(this.offre_emploi.id, {
          date_limite: this.date_limite,
        })
          .then((res) => {
            this.$swal.fire({ title: "l'offre est ouverte", icon: "success" });
          })
          .catch((err) => {
            this.$swal.fire({ title: "Opération échouée", icon: "error" });
            console.log(err);
          });
      }
    },
    showOffre() {
      this.$router.push({
        name: "detail_offre",
        params: { id: this.offre_emploi.id },
      });
    },

    pushOffre() {
      this.$swal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: `Voulez-vous vraiment publier cette offre ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, Publier!",
          cancelButtonText: "Quitter",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Candidature.closeOffre(this.offre_emploi.id, { is_published: true })
              .then((res) => {
                this.$swal.fire({
                  title: `Offre d'emploi publiée`,
                  icon: "success",
                });
                this.$router.push({
                  name: "OffreDisponible",
                });
              })
              .catch((err) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Erreur d'opération !",
                });
                console.log(err);
              });
          }
        });
    },

    closeOffre() {
      this.$swal
        .fire({
          title: "Êtes-vous sûr(e) ?",
          text: `Voulez-vous vraiment clôturer cette candidature ?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, Cloturer!",
          cancelButtonText: "Quitter",
        })
        .then((result) => {
          if (result.isConfirmed) {
            Candidature.closeOffre(this.offre_emploi.id, { statut: "Fermée" })
              .then((res) => {
                this.$swal.fire({
                  title: `Candidature clôturée`,
                  icon: "success",
                });
                this.$router.push({
                  name: "dashboard_recruteur",
                });
              })
              .catch((err) => {
                this.$swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Erreur d'opération !",
                });
                console.log(err);
              });
          }
        });
    },

    definir_critere() {
      this.criteres.forEach((cri) => {
        OffreEmploi.definir_critere(cri).catch((err) => {
          console.log(err);
          return this.$swal.fire({ title: "opération échouée", icon: "error" });
        });
      });
    },
    noterCandidat(value) {
      this.valueCandidat = value.note;
      this.idCandidat = value.id;
      this.index=value.index;
      this.open = true;
    },
    noted() {
      this.loading = true;
      Candidature.status_candidature(this.idCandidat, { note: this.note })
        .then((res) => {
          this.loading = false;
          this.candidatures[ this.index].note = this.note;
          this.$swal.fire({
            title: `Effectuer avec succès`,
            icon: "success",
          });
          this.open = false;
          this.note = null;
        })
        .catch((err) => {
          this.loading = false;

          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Erreur d'opération !",
          });
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
