<template>
  <DashboardRecruteur>
    <div class="card">
      <div class="card-header">
        <p class="titre" style="color: #cc5500">Nouvelle offre d'emploi</p>
      </div>
      <div class="card-block">
        <form @submit.prevent="confirmForm()" enctype="multipart/form-data">
          <fieldset v-if="currentStep == 1">
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="Poste"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Poste <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  type="text"
                  v-model="offre_emploi.level1.poste"
                  class="form-control n-input bg-light"
                  placeholder="Veuillez entrer le poste"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level1.poste.$invalid &&
                      v$.offre_emploi.level1.poste.$dirty,
                  }"
                  @blur="v$.offre_emploi.level1.poste.$touch"
                />
                <template
                  v-if="
                    v$.offre_emploi.level1.poste.$invalid &&
                    v$.offre_emploi.level1.poste.$dirty
                  "
                >
                  <small
                    v-if="v$.offre_emploi.level1.poste.required.$invalid"
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="Email"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Email pour l'offre
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>

              <div class="col-sm-12">
                <input
                  type="text"
                  v-model="offre_emploi.level1.email"
                  class="form-control n-input bg-light"
                  placeholder="Veuillez entrer l'email à contacter"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="Telephone"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Numéro de téléphone pour l'offre
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>

              <div class="col-sm-12">
                <input
                  type="text"
                  v-model="offre_emploi.level1.numero"
                  class="form-control n-input bg-light"
                  placeholder="Veuillez entrer le numéro de téléphone à joindre pour l'offre"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="Telephone"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Objet de l'email
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>

              <div class="col-sm-12">
                <input
                  type="text"
                  v-model="offre_emploi.level1.object"
                  class="form-control n-input bg-light"
                  placeholder="Veuillez entrer un objet "
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="poste_description"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Description du poste
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <ckeditor
                  :editor="editor"
                  v-model="offre_emploi.level1.description"
                  :config="editorConfig"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level1.description.$invalid &&
                      v$.offre_emploi.level1.description.$dirty,
                  }"
                  @blur="v$.offre_emploi.level1.description.$touch"
                ></ckeditor>
              </div>

              <template
                v-if="
                  v$.offre_emploi.level1.description.$invalid &&
                  v$.offre_emploi.level1.description.$dirty
                "
              >
                <small
                  v-if="v$.offre_emploi.level1.description.required.$invalid"
                  class="invalid-feedback d-block"
                >
                  Ce champs est requis
                </small>
              </template>
            </div>

            <button
              class="btn btn-primary float-end"
              type="button"
              @click="goStep(2, 'suivant')"
            >
              Suivant
            </button>
          </fieldset>
          <fieldset v-if="currentStep == 2">
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="salaire"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Type de contrat
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <v-select
                  v-model="offre_emploi.level2.type_contrat"
                  class="bg-light"
                  :options="types_contrat"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level2.type_contrat.$invalid &&
                      v$.offre_emploi.level2.type_contrat.$dirty,
                  }"
                  @blur="v$.offre_emploi.level2.type_contrat.$touch"
                ></v-select>
                <template
                  v-if="
                    v$.offre_emploi.level2.type_contrat.$invalid &&
                    v$.offre_emploi.level2.type_contrat.$dirty
                  "
                >
                  <small
                    v-if="v$.offre_emploi.level2.type_contrat.required.$invalid"
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="niveau_etude"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Niveau d'Etude minimum
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <select
                  v-model="offre_emploi.level2.niveau_etude"
                  class="bg-light form-select"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level2.niveau_etude.$invalid &&
                      v$.offre_emploi.level2.niveau_etude.$dirty,
                  }"
                  @blur="v$.offre_emploi.level2.niveau_etude.$touch"
                >
                  <option
                    :value="niveau"
                    v-for="(niveau, index) in niveau_etudes"
                    :key="index"
                  >
                    {{ niveau }}
                  </option>
                </select>
                <template
                  v-if="
                    v$.offre_emploi.level2.niveau_etude.$invalid &&
                    v$.offre_emploi.level2.niveau_etude.$dirty
                  "
                >
                  <small
                    v-if="v$.offre_emploi.level2.niveau_etude.required.$invalid"
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="competences"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Compétences requises
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level2.competences"
                  class="form-control bg-light"
                  placeholder="Veuillez entrer les compétences"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level2.competences.$invalid &&
                      v$.offre_emploi.level2.competences.$dirty,
                  }"
                  @blur="v$.offre_emploi.level2.competences.$touch"
                />
                <template
                  v-if="
                    v$.offre_emploi.level2.competences.$invalid &&
                    v$.offre_emploi.level2.competences.$dirty
                  "
                >
                  <small
                    v-if="v$.offre_emploi.level2.competences.required.$invalid"
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="nombre_demande"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Nombre de candidats à embaucher à la fin du recrutement
                  <span style="color: rgb(233, 105, 1)">*</span></label
                >
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level2.nombre_demande"
                  id="nombre_demande"
                  type="number"
                  class="form-control bg-light"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level2.nombre_demande.$invalid &&
                      v$.offre_emploi.level2.nombre_demande.$dirty,
                  }"
                  @blur="v$.offre_emploi.level2.nombre_demande.$touch"
                />
                <template
                  v-if="
                    v$.offre_emploi.level2.nombre_demande.$invalid &&
                    v$.offre_emploi.level2.nombre_demande.$dirty
                  "
                >
                  <small
                    v-if="
                      v$.offre_emploi.level2.nombre_demande.required.$invalid
                    "
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="date_limite"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Date limite pour clôturer l'offre
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level2.date_limite"
                  id="date_limite"
                  type="datetime-local"
                  @input="handleDateChange"
                  class="form-control n-input bg-light"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level2.date_limite.$invalid &&
                      v$.offre_emploi.level2.date_limite.$dirty,
                  }"
                  @blur="v$.offre_emploi.level2.date_limite.$touch"
                />
                <template
                  v-if="
                    v$.offre_emploi.level2.date_limite.$invalid &&
                    v$.offre_emploi.level2.date_limite.$dirty
                  "
                >
                  <small
                    v-if="v$.offre_emploi.level2.date_limite.required.$invalid"
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <button
                class="btn btn-primary"
                @click="goStep(1, 'precedent')"
                type="button"
              >
                précédent
              </button>
              <button
                class="btn btn-primary"
                @click="goStep(3, 'suivant')"
                type="button"
              >
                Suivant
              </button>
            </div>
          </fieldset>
          <fieldset v-if="currentStep == 3">
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="nom_entreprise"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Nom de l'entreprise qui recrute
                  <span style="color: #e96901">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level3.nom_entreprise"
                  id="nom_entreprise"
                  name="nom_entreprise"
                  type="text"
                  class="form-control n-input bg-light"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level3.nom_entreprise.$invalid &&
                      v$.offre_emploi.level3.nom_entreprise.$dirty,
                  }"
                  @blur="v$.offre_emploi.level3.nom_entreprise.$touch"
                />
                <template
                  v-if="
                    v$.offre_emploi.level3.nom_entreprise.$invalid &&
                    v$.offre_emploi.level3.nom_entreprise.$dirty
                  "
                >
                  <small
                    v-if="
                      v$.offre_emploi.level3.nom_entreprise.required.$invalid
                    "
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="localisation"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Localisation <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level3.localisation"
                  id="localisation"
                  name="localisation"
                  type="text"
                  class="form-control n-input bg-light"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level3.localisation.$invalid &&
                      v$.offre_emploi.level3.localisation.$dirty,
                  }"
                  @blur="v$.offre_emploi.level3.localisation.$touch"
                />
                <template
                  v-if="
                    v$.offre_emploi.level3.localisation.$invalid &&
                    v$.offre_emploi.level3.localisation.$dirty
                  "
                >
                  <small
                    v-if="v$.offre_emploi.level3.localisation.required.$invalid"
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="salaire"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Salaire</label
                >
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level3.salaire"
                  id="salaire"
                  name="salaire"
                  type="number"
                  class="form-control n-input bg-light"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="age"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Age requis</label
                >
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level3.age_requis"
                  id="age"
                  name="age"
                  type="number"
                  class="form-control n-input bg-light"
                />
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <button
                @click="goStep(2, 'precedent')"
                type="button"
                class="btn btn-primary"
              >
                Précédent
              </button>
              <button
                @click="goStep(4, 'suivant')"
                type="button"
                class="btn btn-primary"
              >
                Suivant
              </button>
            </div>
          </fieldset>
          <fieldset v-if="currentStep == 4">
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="langue"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Langues requises <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level4.langue_requise"
                  id="langue"
                  type="text"
                  class="form-control n-input bg-light"
                  :class="{
                    'is-invalid':
                      v$.offre_emploi.level4.langue_requise.$invalid &&
                      v$.offre_emploi.level4.langue_requise.$dirty,
                  }"
                  @blur="v$.offre_emploi.level4.langue_requise.$touch"
                />
                <template
                  v-if="
                    v$.offre_emploi.level4.langue_requise.$invalid &&
                    v$.offre_emploi.level4.langue_requise.$dirty
                  "
                >
                  <small
                    v-if="
                      v$.offre_emploi.level4.langue_requise.required.$invalid
                    "
                    class="invalid-feedback d-block"
                  >
                    Ce champs est requis
                  </small>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <label
                  for="centre_interet"
                  class="block"
                  style="color: #1d4851; font-weight: bold"
                  >Centres d'intérêt
                  <span style="color: rgb(233, 105, 1)">*</span>
                </label>
              </div>
              <div class="col-sm-12">
                <input
                  v-model="offre_emploi.level4.centre_interet"
                  class="bg-light form-control"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-10">
                <label
                  class="form-label"
                  for="image"
                  style="color: #1d4851; font-weight: bold"
                  >Image qui accompagnera votre offre
                </label>
                <input
                  id="file-input"
                  @change="($e) => fileChanged($e)"
                  type="file"
                  class="form-control"
                  name="image"
                  accept="image/jpeg, image/png, image/jpg"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-10">
                <label
                  class="form-label"
                  for="image"
                  style="color: #1d4851; font-weight: bold"
                  >Réception des documents hors plateforme :
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    v-model="offre_emploi.is_Email"
                  />
                  <label class="form-check-label" for="flexCheckIndeterminate">
                    Par email
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="offre_emploi.is_Whatsapp"
                  />
                  <label class="form-check-label" for="flexCheckIndeterminate">
                    Par Whatsap
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <button
                @click="goStep(3, 'precedent')"
                class="btn btn-primary"
                type="button"
              >
                Précédent
              </button>
              <button type="submit" class="btn btn-primary">
                <div
                  class="spinner-grow text-light"
                  role="status"
                  v-if="loading"
                ></div>
                Terminer
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </DashboardRecruteur>
</template>

<script>
import DashboardRecruteur from "../DashboardView.vue";
import { OffreEmploi } from "@/services/offre_emploi.service";
import { infoService, accountService } from "@/services";
import Swal from "sweetalert2";
import validationHelpers from "@/utils/validation";
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: { DashboardRecruteur },

  data() {
    return {
      loading: false,
      v$: useValidate(),
      editor: ClassicEditor,
      editorData: "<p>Veuillez mettre la description ici</p>",
      editorConfig: {},
      recruteur: null,
      postes: null,
      currentStep: 1,
      types_contrat: ["CDD", "CDI", "FREELANCE", "STAGE"],
      competences: [],
      niveau_etudes: [],
      centre_interets: [],
      offre_emploi: {
        is_Email: false,
        is_Whatsapp: false,
        level1: {
          poste: null,
          description: "",
          email: null,
          numero: null,
          object: null,
        },
        level2: {
          type_contrat: "",
          niveau_etude: null,
          competences: "",
          nombre_demande: 1,
          date_limite: null,
        },
        level3: {
          nom_entreprise: "",
          localisation: "",
          salaire: 0,
          age_requis: 18,
        },
        level4: {
          langue_requise: "",
          centre_interet: "",
          image: null,
        },
      },
      mydata: new FormData(),
      errors: {
        emailErrorMessage: null,
        numeroErrorMessage: null,
        competencesErrorMessage: null,
        dateErrorMessage: null,
        emailErrorMessage: null,
        salaireErrorMessage: null,
        imageErrorMessage: null,
      },
    };
  },

  validations() {
    return {
      offre_emploi: {
        level1: {
          poste: { required },
          description: { required },
        },
        level2: {
          type_contrat: { required },
          niveau_etude: { required },
          competences: { required },
          nombre_demande: { required },
          date_limite: { required },
        },
        level3: {
          nom_entreprise: { required },
          localisation: { required },
        },
        level4: {
          langue_requise: { required },
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    isCurrentLevelValid() {
      // Vérifiez la validité des champs du niveau actuel
      if (this.currentStep === 1) {
        return this.v$.offre_emploi.level1.$invalid;
      } else if (this.currentStep === 2) {
        return this.v$.offre_emploi.level2.$invalid;
      } else if (this.currentStep === 3) {
        return this.v$.offre_emploi.level3.$invalid;
      }
    },
  },
  methods: {
    ...validationHelpers,
  
    handleDateChange(event) {
      const selectedDate = new Date(event.target.value);
      const today = new Date();

      if (selectedDate < today) {
        // Empêcher la mise à jour de la valeur si la date sélectionnée est antérieure à la date d'aujourd'hui
        event.target.value = "";
      }
    },

    goStep(step, action) {
      if (action == "suivant" && !this.isCurrentLevelValid) {
        this.currentStep = step;
      } else if (action == "precedent") {
        this.currentStep = step;
      } else {
        this.forceValidation();
        return;
      }
    },
    confirmForm() {
      // console.log(this.offre_emploi);
      this.create_offre();
    },
    fileChanged(e) {
      document
        .getElementById("file-input")
        .addEventListener("change", function () {
          this.value = ""; // Vider le champ de fichier après la sélection d'un fichier
        });
      if (e.target.files[0].size / (1024 * 1024) > 10) {
        Swal.fire({
          icon: "error",
          title: "Image non téléchargée",
          text: " Veuillez télécharger une image inférieurá 10 MB s'il vous plaît",
          timer: 3000,
        });
        document.getElementById("file-input").value = "";
        return;
      }
      this.offre_emploi.level4.image = e.target.files[0];
      this.mydata.append("image", this.offre_emploi.level4.image);
    },
    create_offre() {
      if (this.v$.$invalid) {
        this.forceValidation();
        return;
      }
      this.loading = true;
      this.mydata.append(
        "nom_entreprise",
        this.offre_emploi.level3.nom_entreprise
      );
      this.mydata.append(
        "telephone_destination",
        this.offre_emploi.level1.numero
      );
      this.mydata.append("is_Email", this.offre_emploi.is_Email);
      this.mydata.append("is_Whatsapp", this.offre_emploi.is_Whatsapp);

      this.mydata.append("email_destination", this.offre_emploi.level1.email);
      this.mydata.append("objet_email", this.offre_emploi.level1.object);

      this.mydata.append("localisation", this.offre_emploi.level3.localisation);
      this.mydata.append("type_contrat", this.offre_emploi.level2.type_contrat);
      this.mydata.append("date_limite", this.offre_emploi.level2.date_limite);
      this.mydata.append(
        "langue_requise",
        this.offre_emploi.level4.langue_requise
      );
      if (!this.offre_emploi.level3.age_requis) {
        this.mydata.append("age_requis", 0);
      } else {
        this.mydata.append("age_requis", this.offre_emploi.level3.age_requis);
      }
      if (!this.offre_emploi.level3.salaire) {
        this.mydata.append("salaire", 0);
      } else {
        this.mydata.append("salaire", this.offre_emploi.level3.salaire);
      }
      this.mydata.append(
        "nombre_demande",
        this.offre_emploi.level2.nombre_demande
      );

      this.mydata.append("competences", this.offre_emploi.level2.competences);
      this.mydata.append("recruteur", this.user.id);

      this.mydata.append("poste", this.offre_emploi.level1.poste);
      this.mydata.append(
        "centre_interet",
        this.offre_emploi.level4.centre_interet
      );
      this.mydata.append("niveau_etude", this.offre_emploi.level2.niveau_etude);
      this.mydata.append("description", this.offre_emploi.level1.description);
      OffreEmploi.create_offre(this.mydata)
        .then((res) => {
          this.loading = false;

          this.mydata = new FormData();
          if (res.status == 201) {
            Swal.fire({
              icon: "success",
              title: "Créé",
              text: "Une nouvelle offre a été créer avec success!",
              showConfirmButton: false,
              timer: 2000,
            }).then((res) => {
              console.log(res);
              this.$router.push({ name: "dashboard_recruteur" });

              //revenir sur la page précédente
              //history.back();
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: "Operation échoué",
            text: "l'offre n'a pas pu etre créé",
            timer: 2000,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    infoService
      .get_all_centre_interet()
      .then((res) => (this.centre_interets = res.data.results))
      .catch((err) => console.log(err));

    this.niveau_etudes = infoService.get_all_niv_etude();

    // infoService
    //   .get_all_post()
    //   .then((res) => (this.postes = res.data.results))
    //   .catch((err) => console.log(err));
  },
};
</script>

<style>
@import "vue-select/dist/vue-select.css";

fieldset {
  background-color: white;
}

.wizard > .content {
  background-color: white;
}

.titre {
  color: #1d4851;
  font-size: 20px;
  font-weight: bold;
}

.multi_select .vs__selected {
  background-color: #4099ff;
  color: white;
}

.multi_select {
  background-color: rgb(248, 249, 250);
}
</style>
